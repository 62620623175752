import { ref } from 'vue';

export default function createTextSensor({ ID, Name, State, LastUpdate }) {
    const sensor = {
        ID: ref(ID),
        Name: ref(Name),
        State: ref(State),
        LastUpdate: ref(LastUpdate),
        valuesFromSource({ Name, State, LastUpdate }) {
            sensor.Name.value = Name;
            sensor.State.value = State;
            sensor.LastUpdate.value = LastUpdate;
        },
        updateState(ts, state) {
            sensor.State.value = state;
            sensor.LastUpdate.value = ts;
        }
    };

    return sensor;
}
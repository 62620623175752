<template>
    <div class="block rain-block">
        <h3 class="block-header">Rain</h3>
        <div class="rain-block-features">
            <div class="primary-metric">
                <div class="val primary"><span class="mono">{{ numeric(rainRateLast?.State, 1,
                    rainRateLast?.AccuracyDecimals || 0) }}</span> <span class="unit">in/hr</span></div>
                <div class="label">Current Rate</div>
            </div>
            <div class="rainfall-chart">
                <figure id="rainfall-chart"></figure>
            </div>
            <div class="rainrate-chart">
                <figure id="rainrate-chart"></figure>
            </div>
        </div>
    </div>
</template>
<script>
import { numeric } from "../util";

export default {
    name: 'RainBlock',
    props: ['rainRateLast'],
    async setup() {
        return {
            numeric,
        };
    },
}
</script>
<style lang="scss">
.weather {
    .blocks {
        .rain-block {
            .rain-block-features {
                display: grid;
                grid-template-columns: 400px 500px 1fr;
                grid-template-rows: 1fr;
                grid-column-gap: 0px;
                grid-row-gap: 0px;

                .primary-metric {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                }

                .rainfall-chart {
                    min-height: 400px;
                    height: 400px;

                    // // shrink xaxis labels
                    // .highcharts-xaxis-labels {
                    //     font-size: 0.6rem;
                    // }
                }

                .rainrate-chart {
                    min-height: 400px;
                    height: 400px;
                }

                @media only screen and (max-width: 1400px) {
                    grid-template-columns: repeat(2, 1fr);
                    grid-template-rows: 1fr 300px;

                    .rainrate-chart {
                        grid-column: 1 / span 2;
                        min-height: 300px;
                    }

                    .rainfall-chart {
                        min-height: 200px;
                    }
                }

                @media only screen and (max-width: 600px) {
                    grid-template-columns: 1fr;
                    grid-template-rows: 100px repeat(2, 1fr);

                    .rainrate-chart {
                        grid-column: 1 / span 1;
                    }
                }

                // iphone
                @media only screen and (max-height: 850px) {

                    // shorten the chart height so it doesn't take up so much of the screen
                    .rainfall-chart {
                        min-height: 200px;
                        height: 200px;
                    }

                    .rainrate-chart {
                        min-height: 200px;
                        height: 200px;
                    }
                }
            }
        }
    }

    &.weather--kioskmode {
        .blocks {
            .rain-block {
                .rain-block-features {
                    grid-template-columns: repeat(2, 1fr);
                    grid-template-rows: 240px 1fr;

                    .rainfall-chart {
                        min-height: 250px;
                        height: 250px;
                    }

                    .rainrate-chart {
                        min-height: 300px;
                        height: 300px;
                        grid-column: 1 / span 2;

                        @media only screen and (max-width: 1200px) {
                            display: none;
                            min-height: 200px;
                        }
                    }

                    // kindle fire kisok mode
                    @media only screen and (max-height: 1280px) {

                        // reduce label size
                        .label {
                            font-size: 0.7rem;
                        }

                        // reduce chart height
                        .rainfall-chart {
                            min-height: 200px;
                            height: 200px;
                        }
                    }

                    @media only screen and (max-width: 1200px) {
                        grid-template-columns: 1fr;
                        grid-template-rows: 100px 150px;
                        // .rainrate-chart {
                        //     display: block;
                        // }
                    }
                }
            }
        }
    }
}
</style>
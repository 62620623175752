import Highcharts from "highcharts";
import Moment from "moment";
import { intervalAppropriateDate, numeric } from "../../util";

export default function () {

    const chart = Highcharts.chart('rainrate-chart', {
        chart: {
            type: 'line',
            styledMode: true
        },
        credits: {
            enabled: false
        },
        title: {
            text: 'Rain Rate',
            align: 'left'
        },
        subtitle: {
            text: 'Today',
            align: 'left'
        },
        xAxis: {
            type: 'datetime',
            labels: {
                overflow: 'justify',
                formatter() {
                    return intervalAppropriateDate(this.value, this.axis.series[0].options.pointInterval);
                },
            },
            plotBands: [{
                color: '#ffdab9',
                from: Moment('Jan 7, 2022 8:00 AM'),
                to: Moment('Feb 19, 2022 6:00 PM'),
                label: {
                    text: 'Station Offline',
                }
            }],
        },
        yAxis: {
            title: {
                text: 'in/hr'
            },
            minorGridLineWidth: 0,
            gridLineWidth: 0,
            alternateGridColor: null,
        },
        tooltip: {
            formatter() {
                return `${intervalAppropriateDate(this.x, this.series.options.pointInterval)}<br>${this.series.name} <b>${numeric(this.y, 1, 2)} in/hr</b>`;
            }
        },
        legend: {
            enabled: false
        },
        plotOptions: {
            line: {
                step: 'left',
                lineWidth: 4,
                states: {
                    hover: {
                        lineWidth: 5
                    }
                },
                marker: {
                    enabled: false
                },
            }
        },
        series: [{
            name: "Rain Rate",
            data: []
        }],
        annotations: [{
            animation: {
                defer: 0
            },
            draggable: 'xy',
            labelOptions: {
                shape: 'connector',
                align: 'right',
                justify: false,
                crop: false,
                style: {
                    fontSize: '0.7rem',
                    textOutline: '1px white'
                }
            },
            labels: [{}],
        },]
    });

    return {
        chart,
        avg(m) {
            chart.series[0].setData(m.Values);
            chart.series[0].update({
                pointInterval: m.Interval * 1000,
                pointStart: Moment(m.StartDate).valueOf(),
            });
        },
        max_label: (m) => {
            if (!m) {
                return;
            }

            const ts = Moment(m.Timestamp);
            const toTrunc = ts.valueOf() % chart.series[0].userOptions.pointInterval;
            const startOf = Moment(ts.valueOf() - toTrunc);

            const x = startOf.valueOf();
            const y = numeric(m.Value, 1, 2);

            chart.update({
                annotations: [{
                    labels: [{
                        allowOverlap: true,
                        text: `High - ${y} in/hr`,
                        point: {
                            x,
                            y,
                            xAxis: 0,
                            yAxis: 0
                        }
                    },],
                },]
            })
        },
    };
}
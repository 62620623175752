<template>
    <div class="weather" :data-station-id="$config.stationid"
        :class="{ 'weather--kioskmode': isKioskMode, 'weather--grayscale': isGrayscale }">
        <div class="header">
            <div class="header__left">
                <h1>{{ $config.title }}</h1>
                <h2>{{ $config.subtitle }}</h2>
            </div>
            <div class="header__right">
                <div class="range-label">
                    <h4>{{ rangeLabel }}</h4>
                </div>
                <div class="data-notice">
                    <div class="last-update" v-if="lastUpdate">Updated {{ moment(lastUpdate).format("MM/DD, HH:mm:ss") }}
                    </div>
                    <div class="last-update" v-else>Loading...</div>
                </div>
                <div class="nav" v-if="!isKioskMode">
                    <div>
                        <button @click="navYear">Year</button>
                    </div>
                    <div>
                        <button @click="navMonth">Month</button>
                    </div>
                    <div>
                        <button @click="navWeek">Week</button>
                    </div>
                    <div>
                        <button @click="navDay">Day</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="weather-current">
            <div class="alerts">
                <div class="alert__weather" v-if="textForecasts.nws_alert?.EffectiveEvent">
                    ⚠ {{ textForecasts.nws_alert?.EffectiveEvent?.Value }}
                </div>
            </div>
            <div class="forecast" v-if="textForecasts.nws_synopsis">
                {{ textForecasts.nws_synopsis?.EffectiveEvent?.Value }}
            </div>
            <div class="blocks">
                <template v-if="isKioskMode">
                    <div class="column">
                        <SunBlock :sunriseRel="forecasts.sunrise_rel" :sunsetRel="forecasts.sunset_rel"
                            :dayLength="forecasts.day_length" />
                        <TempBlock :temp="sensors.temp" :heatIndex="sensors.heat_index" :tempHigh="aggrThisPeriod.temp_high"
                            :tempLow="aggrThisPeriod.temp_low" :hum="sensors.hum" :windChill="sensors.wind_chill"
                            :dewPoint="sensors.dew_point" :wetBulb="sensors.wet_bulb" @vue:mounted="tempBlockMounted" />
                        <WindBlock :windSpeedLast="sensors.wind_speed_last"
                            :windDirCardinalLast="textSensors.wind_dir_cardinal_last"
                            :windSpeedAvgLast10Min="sensors.wind_speed_avg_last_10_min"
                            :windDirCardinalAvgLast10Min="textSensors.wind_dir_cardinal_avg_last_10_min"
                            :windSpeedHiLast10Min="sensors.wind_speed_hi_last_10_min"
                            :windDirCardinalAtHiSpeedLast10Min="textSensors.wind_dir_cardinal_at_hi_speed_last_10_min"
                            @vue:mounted="windBlockMounted" />
                        <AQIBlock :nowcastAQI="sensors.nowcast_aqi" :nowcastAQICat="textSensors.nowcast_aqi_cat"
                            @vue:mounted="aqiBlockMounted" />
                    </div>
                    <div class="column">
                        <UVBlock :uvIndex="sensors.uv_index" :uvIndexDesc="textSensors.uv_index_desc"
                            @vue:mounted="uvBlockMounted" />
                        <RainBlock :rainRateLast="sensors.rain_rate_last" @vue:mounted="rainBlockMounted" />
                        <BarometerBlock :barAbsolute="sensors.bar_absolute" :barTrendDesc="textSensors.bar_trend_desc"
                            @vue:mounted="barometerBlockMounted" />
                        <TideBlock :tidePred="forecasts.tide_pred" :tidePredDir="textForecasts.tide_pred_direction"
                            :tidePredHigh="forecasts.tide_pred_high" :tidePredLow="forecasts.tide_pred_low"
                            :rangeAppropriateDate="rangeAppropriateDate" @vue:mounted="tideBlockMounted" />
                    </div>
                </template>
                <template v-else>
                    <SunBlock :sunriseRel="forecasts.sunrise_rel" :sunsetRel="forecasts.sunset_rel"
                        :dayLength="forecasts.day_length" />
                    <TempBlock :temp="sensors.temp" :heatIndex="sensors.heat_index" :tempHigh="aggrThisPeriod.temp_high"
                        :tempLow="aggrThisPeriod.temp_low" :hum="sensors.hum" :windChill="sensors.wind_chill"
                        :dewPoint="sensors.dew_point" :wetBulb="sensors.wet_bulb" @vue:mounted="tempBlockMounted" />
                    <RainBlock :rainRateLast="sensors.rain_rate_last" @vue:mounted="rainBlockMounted" />
                    <WindBlock :windSpeedLast="sensors.wind_speed_last"
                        :windDirCardinalLast="textSensors.wind_dir_cardinal_last"
                        :windSpeedAvgLast10Min="sensors.wind_speed_avg_last_10_min"
                        :windDirCardinalAvgLast10Min="textSensors.wind_dir_cardinal_avg_last_10_min"
                        :windSpeedHiLast10Min="sensors.wind_speed_hi_last_10_min"
                        :windDirCardinalAtHiSpeedLast10Min="textSensors.wind_dir_cardinal_at_hi_speed_last_10_min"
                        @vue:mounted="windBlockMounted" />
                    <UVBlock :uvIndex="sensors.uv_index" :uvIndexDesc="textSensors.uv_index_desc"
                        @vue:mounted="uvBlockMounted" />
                    <AQIBlock :nowcastAQI="sensors.nowcast_aqi" :nowcastAQICat="textSensors.nowcast_aqi_cat"
                        @vue:mounted="aqiBlockMounted" />
                    <BarometerBlock :barAbsolute="sensors.bar_absolute" :barTrendDesc="textSensors.bar_trend_desc"
                        @vue:mounted="barometerBlockMounted" />
                    <TideBlock :tidePred="forecasts.tide_pred" :tidePredDir="textForecasts.tide_pred_direction"
                        :tidePredHigh="forecasts.tide_pred_high" :tidePredLow="forecasts.tide_pred_low"
                        :rangeAppropriateDate="rangeAppropriateDate" @vue:mounted="tideBlockMounted" />
                </template>
            </div>
        </div>
        <div v-if="!isKioskMode" class="coords">Station Location: {{ $config.stationCoordinates }}</div>
    </div>
</template>
<script>
import $config from "../config.js";
import { useRoute } from 'vue-router'
import { ref, computed, watch } from "vue";
import Moment from "moment";
import Highcharts from "highcharts";
import annotations from 'highcharts/modules/annotations';
import more from 'highcharts/highcharts-more';
import multicolor from 'highcharts-multicolor-series';

import { useSyncManager } from "@/syncManager";
import { useChartManager } from "@/chartManager";
import { useStore } from "@/store";

import makeTidesChart from "../lib/charts/tides";
import makeTempChart from "../lib/charts/temp";
import makeBarometerChart from "../lib/charts/barometer";
import makeWindRoseChart from "../lib/charts/windRose";
import makeRainRateChart from "../lib/charts/rainRate";
import makeRainfallChart from "../lib/charts/rainfall";
import makeWindChart from "../lib/charts/wind";
import makeAQIChart from "../lib/charts/aqi";
import makeUVIndexChart from "../lib/charts/uvindex";

import RainBlock from "../components/RainBlock";
import TempBlock from "../components/TempBlock";
import WindBlock from "../components/WindBlock";
import BarometerBlock from "../components/BarometerBlock";
import AQIBlock from "../components/AQIBlock";
import TideBlock from "../components/TideBlock";
import SunBlock from "../components/SunBlock";
import UVBlock from "../components/UVBlock";

window.Highcharts = Highcharts
more(Highcharts);
annotations(Highcharts);
multicolor(Highcharts);

export default {
    name: 'IndexPage',
    head() {
        return {
            title: $config.title,
            link: [{
                rel: "stylesheet",
                href: `/${$config.stationid}.css`
            }]

        }
    },
    components: {
        RainBlock,
        TempBlock,
        WindBlock,
        BarometerBlock,
        TideBlock,
        SunBlock,
        AQIBlock,
        UVBlock,
    },
    async setup() {
        const route = useRoute()
        const { state, viewWindowMode, aggrThisPeriod } = useStore();
        const { fetchInitialConditions, initializeWebSocket, lastUpdate } = useSyncManager(state);
        const { registerChart, rebuildChartDependencies, updateChartsForEntity, rebuildCharts, charts } = useChartManager(state, viewWindowMode);

        const isKioskMode = ref(route.query.kiosk == "true");
        const isGrayscale = ref(route.query.grayscale == "true");

        // TODO: reevaluate dates at midnight
        const { navYear, navMonth, navWeek, navDay } = {
            navYear() {
                state.startDate.value = Moment().startOf('year').unix()
                state.endDate.value = Moment().endOf('year').unix()
            },
            navMonth() {
                state.startDate.value = Moment().startOf('month').unix()
                state.endDate.value = Moment().endOf('month').unix()
            },
            navWeek() {
                state.startDate.value = Moment().startOf('week').unix()
                state.endDate.value = Moment().endOf('week').unix()
            },
            navDay() {
                state.startDate.value = Moment().startOf('day').unix()
                state.endDate.value = Moment().endOf('day').unix()
            },
        }
        navDay()

        watch(viewWindowMode, () => {
            rebuildChartDependencies();
            rebuildCharts();
        });

        function onEntityUpdate(entityType, entityID) {
            updateChartsForEntity(entityType, entityID);
        }

        function getFormatForRange(hours) {
            if (hours < 24) return "h:mm A";
            if (hours < 24 * 7) return "dd hh:mm A";
            return "MM/DD hh:mm A";
        }

        function formatRangeAppropriateDate(d, { start, end } = {}) {
            if (!d) return "---";
            const hours = (end - start) / 60 / 60;
            return Moment(d).format(getFormatForRange(hours));
        }

        const rangeAppropriateDate = (v) => formatRangeAppropriateDate(v, { start: state.startDate, end: state.endDate });

        let evalMounted = async () => {
            const mounted = Object.keys(charts).length;
            console.log(`mounted: ${mounted}/7`);
            if (mounted == 9) {
                rebuildChartDependencies();
                await fetchInitialConditions();
                console.log("done initial fetch");
                initializeWebSocket(onEntityUpdate);
                rebuildCharts();
            }
        };

        return {
            isGrayscale,
            isKioskMode,
            ...state,
            aggrThisPeriod,
            charts,
            lastUpdate,
            navDay,
            navYear,
            navWeek,
            navMonth,
            $config,
            moment: Moment,

            tempBlockMounted: () => {
                registerChart("temp", makeTempChart("temp-chart", { rangeAppropriateDate }));
                return evalMounted();
            },
            rainBlockMounted: () => {
                registerChart("rainrate", makeRainRateChart("rainrate-chart", { rangeAppropriateDate }));
                registerChart("rainfall", makeRainfallChart("rainfall-chart", { rangeAppropriateDate }));
                return evalMounted();
            },
            windBlockMounted: () => {
                registerChart("wind", makeWindChart("wind-chart", { rangeAppropriateDate }));
                registerChart("windrose", makeWindRoseChart("windrose-chart"));
                return evalMounted();
            },
            barometerBlockMounted: () => {
                registerChart("bar", makeBarometerChart("barometer-chart"));
                return evalMounted();
            },
            tideBlockMounted: () => {
                registerChart("tide", makeTidesChart("tide-chart", { rangeAppropriateDate }));
                return evalMounted();
            },
            aqiBlockMounted: () => {
                registerChart("aqi", makeAQIChart("aqi-chart"));
                return evalMounted();
            },
            uvBlockMounted: () => {
                registerChart("uv", makeUVIndexChart("uv-chart"));
                return evalMounted();
            },

            // weather alert is an array of strings, so we must parse
            weatherAlert: computed(() => state.sensors.weather_alert?.Value ? JSON.parse(state.sensors.weather_alert?.Value) : null),
            rangeAppropriateDate,
            rangeLabel: computed(() => {
                const hours = (state.endDate - state.startDate) / 60 / 60;
                if (hours <= 24) {
                    return Moment(state.startDate * 1000).format("dddd, MMM D, YYYY");
                } else if (hours <= 168) {
                    return Moment(state.startDate * 1000).format("MMM D") + " - " + Moment((state.startDate * 1000) + (1000 * 60 * 60 * 24 * 7)).format("MMM D");
                } else if (hours <= 750) {
                    return Moment(state.startDate * 1000).format("MMMM YYYY");
                } else if (hours <= 8760) {
                    return Moment(state.startDate * 1000).format("YYYY");
                } else {
                    return ""
                }
            }),
        };
    },
}
</script>
<style lang="scss">
//@import "@picocss/pico";
@import "highcharts/css/highcharts";
@import "../../public/eglon.css";
@import "../../public/lemolo.css";
@import "~/src/scss/dark-mode.scss";
@import "~/src/scss/grayscale-mode.scss";
@import url('https://fonts.googleapis.com/css2?family=Chivo+Mono:wght@300&display=swap');

:root {
    --base-color: #415462;
    --spacing: 1rem;
    --block-spacing-vertical: var(--spacing);
    --block-spacing-horizontal: var(--spacing);
    --border-radius: 0.25rem;
    --card-background-color: #fff;
    --card-box-shadow: 0.0145rem 0.029rem 0.174rem rgba(27, 40, 50, .017), 0.0335rem 0.067rem 0.402rem rgba(27, 40, 50, .024), 0.0625rem 0.125rem 0.75rem rgba(27, 40, 50, .03), 0.1125rem 0.225rem 1.35rem rgba(27, 40, 50, .036), 0.2085rem 0.417rem 2.502rem rgba(27, 40, 50, .043), 0.5rem 1rem 6rem rgba(27, 40, 50, .06), 0 0 0 0.0625rem rgba(27, 40, 50, .015);
    --primary: #1095c1;
    --primary-hover: #08769b;
    --primary-focus: rgba(16, 149, 193, .125);
    --primary-inverse: #fff;
    --form-element-spacing-vertical: 0.75rem;
    --form-element-spacing-horizontal: 1rem;
    --transition: 0.2s ease-in-out;

    --block-height-temp: 635px;
    --block-height-wind: 700px;
    --block-height-aqi: 300px;
    --block-height-sun: 100px;
    --block-height-rain: 650px;
    --block-height-barometer: 300px;
    --block-height-tide: 500px;
    --block-height-uv: 270px;
}

.weather--kioskmode {

    // kindle fire kiosk mode
    @media only screen and (max-height: 1280px) {
        --block-height-temp: 345px;
        --block-height-wind: 320px;
        --block-height-aqi: 115px;
        --block-height-sun: 100px;
        --block-height-rain: 335px;
        --block-height-barometer: 180px;
        --block-height-tide: 500px;
        --block-height-uv: 115px;
    }
}

html,
body,
#app {
    height: 100%;
    font-family: system-ui, -apple-system, "Segoe UI", "Roboto", "Ubuntu", "Cantarell", "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    line-height: 1.5;
    font-size: 20px;
    color: var(--base-color);
    margin: 0;
}

h1 {
    color: #1b2832;
}

h2 {
    font-size: 1.75rem;
    color: #24333e;
}

h3 {
    color: #2c3d49;
}

h4 {
    color: #374956;
    font-size: 1.25rem;
}

h1,
h2,
h3,
h4 {
    margin: 0;
}

button {
    --background-color: var(--primary);
    --border-color: var(--primary);
    --color: var(--primary-inverse);
    --box-shadow: var(--button-box-shadow, 0 0 0 transparent);
    padding: var(--form-element-spacing-vertical) var(--form-element-spacing-horizontal);
    border: var(--border-width) solid var(--border-color);
    border-radius: var(--border-radius);
    outline: 0;
    background-color: var(--background-color);
    box-shadow: var(--box-shadow);
    color: var(--color);
    font-weight: var(--font-weight);
    font-size: 1rem;
    line-height: var(--line-height);
    text-align: center;
    cursor: pointer;
    transition: background-color var(--transition), border-color var(--transition), color var(--transition), box-shadow var(--transition);

    &:active {
        --background-color: var(--primary-hover);
        --border-color: var(--primary-hover);
        --box-shadow: var(--button-hover-box-shadow, 0 0 0 transparent);
        --color: var(--primary-inverse);
    }

    &:focus {
        --box-shadow: var(--button-hover-box-shadow, 0 0 0 transparent), 0 0 0 var(--outline-width) var(--primary-focus);
    }

    &:hover {
        --background-color: var(--primary-hover);
        --border-color: var(--primary-hover);
        --box-shadow: var(--button-hover-box-shadow, 0 0 0 transparent);
        --color: var(--primary-inverse);
    }
}

.weather {
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: 100%;

    // monospace
    .mono {
        font-family: 'Chivo Mono', monospace;
    }

    // iphone
    @media only screen and (max-width: 390px) {

        // move the nav buttons closer together
        .nav>div {
            margin-left: 0.5rem !important;
        }

        // remove chart margins
        figure {
            margin: 0;
        }
    }

    .header {
        padding: 1rem;
        background-color: var(--header-color);
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(360px, 1fr));

        .header__left {
            h1 {
                margin-bottom: 0;
            }

            h2 {
                margin-bottom: 0;
                color: darkslategray;
                font-size: 1rem;
            }
        }

        .header__right {
            text-align: right;

            .nav {
                margin-top: 10px;
                display: flex;
                justify-content: flex-end;

                &>div {
                    margin-left: 1rem;
                    height: 3rem;
                }
            }
        }

        @media only screen and (max-width: 800px) {
            margin: 0;

            .header__left {
                h1 {
                    margin-bottom: 0;
                }
            }

            .header__right {
                .range-label h4 {}
            }
        }

        @media only screen and (max-height: 1000px) {
            h2 {
                display: none;
            }
        }
    }

    .weather-current {
        flex-grow: 1;
        padding: 1rem;
        flex: 1;
        background-color: whitesmoke;

        @media only screen and (max-width: 600px) {
            padding: 5px;
        }

        .blocks {
            .block {
                margin: var(--block-spacing-vertical) 0;
                padding: var(--block-spacing-vertical) var(--block-spacing-horizontal);
                border-radius: var(--border-radius);
                background: var(--card-background-color);
                box-shadow: var(--card-box-shadow);

                .block-header {
                    text-align: left;

                    @media only screen and (max-height: 1400px) {
                        text-align: center;
                        font-size: .8rem;
                        margin-bottom: 0;
                    }
                }

                @media only screen and (max-width: 800px) {
                    padding: 2px;
                }
            }
        }

        figure {
            height: 100%;
            margin-bottom: 0;
        }

        .alerts {
            .alert__weather {
                color: darkred;
                font-weight: 600;
            }
        }

        .forecast {
            margin: 0.5rem 1rem 0 1rem;
            font-style: italic;
        }

        .range-label {
            display: flex;
            justify-content: flex-end;
        }

        .val {
            div {
                display: inline-block;
            }

            &.primary {
                font-size: 3rem;
                text-align: center;
            }
        }

        .secondary-detail .val {
            font-size: 1.8rem;
        }

        .unit {
            font-size: 1rem;
        }

        .interval {
            text-align: right;
        }

        .coords {
            width: 100%;
            text-align: center;
            padding: 1rem;
        }

        .tide__detail .detail-table {
            margin-top: 1rem;
        }

        .data-notice {
            color: gray;
            font-style: italic;
            display: flex;
            justify-content: space-between;
        }

        .highcharts-background {
            fill: none;
            background-color: transparent;
        }

        .highcharts-annotation-label text {
            fill: #374956;
        }

        .label--long {
            display: inline;
        }

        .label--short {
            display: none;
        }

        .data-notice {
            margin-left: 1rem;
        }

        @media only screen and (max-width: 800px) {

            // .detail-table {
            //     font-size: .8rem;
            // }
            .highcharts-title {
                display: none;
            }

            .highcharts-annotation,
            .highcharts-legend {
                display: none;
            }
        }


    }

    &.weather--kioskmode {

        --spacing: 0.8rem;

        .header {
            width: 100%;

            .header__left {
                display: none;
            }

            .header__right {
                display: flex;
                justify-content: space-between;
            }
        }

        .weather-current {
            overflow: hidden;
            display: flex;
            flex-direction: column;
            padding: 0.5rem;

            .alerts,
            .forecast {
                text-align: center;
            }

            .forecast {
                margin-bottom: 0.5rem;
            }

            .val.primary {
                line-height: 1.1;
            }

            .blocks {
                min-height: 0;
                min-width: 0;
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                grid-template-rows: 1fr;
                grid-column-gap: 15px;
                grid-row-gap: 15px;
                flex-grow: 1;

                .block {
                    margin: 0;
                }

                .column {
                    min-height: 0;
                    min-width: 0;
                    overflow: hidden;
                    display: grid;
                    grid-column-gap: 15px;
                    grid-row-gap: 15px;

                    &:nth-of-type(1) {
                        grid-template-columns: 1fr;
                        grid-template-rows: var(--block-height-sun) var(--block-height-temp) var(--block-height-wind) var(--block-height-aqi);
                    }

                    &:nth-of-type(2) {
                        grid-template-columns: 1fr;
                        grid-template-rows: var(--block-height-uv) var(--block-height-rain) var(--block-height-barometer) var(--block-height-tide);
                    }
                }
            }
        }

        // kindle fire kiosk mode
        @media only screen and (max-height: 1280px) {

            .val.primary {
                font-size: 2.5rem;
            }

            // shrink xaxis labels
            .highcharts-xaxis-labels {
                font-size: 0.6rem;
            }

            // shrink yaxis labels
            .highcharts-yaxis-labels,
            .highcharts-yaxis {
                font-size: 0.6rem;
            }

            // remove chart margins
            figure {
                margin: 0;
            }
        }
    }
}
</style>
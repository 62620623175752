import Highcharts from "highcharts";
import Moment from "moment";
import { intervalAppropriateDate, numeric } from "../../util";

// function indexOfMax(arr) {
//     if (arr.length === 0) {
//         return -1;
//     }

//     let max = arr[0];
//     let maxIndex = 0;

//     for (let i = 1; i < arr.length; i++) {
//         if (arr[i] > max) {
//             maxIndex = i;
//             max = arr[i];
//         }
//     }

//     return maxIndex;
// }

// function indexOfMin(arr) {
//     if (arr.length === 0) {
//         return -1;
//     }

//     let min = arr[0];
//     let minIndex = 0;

//     for (let i = 1; i < arr.length; i++) {
//         if (arr[i] < min) {
//             minIndex = i;
//             min = arr[i];
//         }
//     }

//     return minIndex;
// }

// tideHighs/tideLows are all highs and all lows (4 per day)
// tideMax/tideMin are daily mins and maxes

export default function (selector, {
    startDate,
    endDate,
    rangeAppropriateDate
}) {
    var min = [];
    var max = [];

    const isLowResChart = ((endDate - startDate) / 60 / 60) > 24 * 32;
    // const isMinMaxAnnotations = ((endDate - startDate) / 60 / 60) > 24 * 10;

    // let highs, highTimes;
    // let lows, lowTimes;
    // if (isMinMaxAnnotations) {
    //     const highIdx = indexOfMax(tideHighs.Data);
    //     const lowIdx = indexOfMin(tideLows.Data);

    //     highs = [tideHighs.Data[highIdx]];
    //     lows = [tideLows.Data[lowIdx]];

    //     highTimes = [tideHighs.Times[highIdx]];
    //     lowTimes = [tideLows.Times[lowIdx]];
    // } else {
    //     highs = tideHighs.Data || [];
    //     lows = tideLows.Data || [];

    //     highTimes = tideHighs.Times;
    //     lowTimes = tideLows.Times;
    // }

    // merge highs & lows
    // const highLow = [];
    // if (isLowResChart) {
    //     for (let i = 0; i < tideMax.Data.length; i++) {
    //         highLow.push([parseFloat(tideMin.Data[i].toFixed(1)), parseFloat(tideMax.Data[i].toFixed(1))]);
    //     }
    // }

    const chart = Highcharts.chart(selector, {
        chart: {
            type: "spline",
            styledMode: true
        },
        credits: {
            enabled: false
        },
        title: {
            text: '',
            align: 'left'
        },
        // subtitle: {
        //   text: 'Today',
        //   align: 'left'
        // },
        xAxis: {
            type: 'datetime',
            labels: {
                overflow: 'justify',
                formatter() {
                    return intervalAppropriateDate(this.value, this.axis.series[0].options.pointInterval);
                },
            },
            plotLines: [{
                color: 'red',
                value: Moment(),
                width: 1,
                label: {
                    text: 'now', // Content of the label. 
                    align: 'left', // Positioning of the label. 
                    // Default to center.x: +10 // Amount of pixels the label will be repositioned according to the alignment. 
                }
            }]
        },
        yAxis: {
            title: {
                text: 'ft'
            },
            minorGridLineWidth: 0,
            gridLineWidth: 0,
            alternateGridColor: null,
            plotLines: [{
                color: 'red',
                value: 13,
                width: 1,
                label: {
                    text: '13\'', // Content of the label. 
                    align: 'left', // Positioning of the label. 
                    // Default to center.x: +10 // Amount of pixels the label will be repositioned according to the alignment. 
                }
            }],
        },
        tooltip: {
            formatter() {
                const ts = intervalAppropriateDate(this.x, this.series.options.pointInterval);
                if (isLowResChart) {
                    return `${ts}<br>Tide<br><b>H ${this.point.high} ft<br>L ${this.point.low} ft</b>`;
                } else {
                    return `${ts}<br>${this.series.name} <b>${numeric(this.y, 2, 1)} ft</b>`;
                }
            }
        },
        legend: {
            enabled: false
        },
        plotOptions: {
            spline: {
                lineWidth: 4,
                states: {
                    hover: {
                        lineWidth: 5
                    }
                },
                marker: {
                    enabled: false
                },
            },
            columnrange: {}
        },
        series: [{
            name: "Tide",
            data: [],
            type: "spline"
        },],
        annotations: [{
            animation: {
                defer: 0
            },
            draggable: '',
            x: 0,
            y: 32,
            labelOptions: {
                className: "highcharts-label--tide-high",
                align: 'right',
                justify: false,
                crop: false,
                style: {
                    fontSize: '0.7rem',
                    textOutline: '1px white',
                },
                x: 0,
                y: 32,
            },
            labels: [{}],
        },
        {
            animation: {
                defer: 0
            },
            draggable: 'xy',
            labelOptions: {
                className: "highcharts-label--tide-low",
                align: 'right',
                justify: false,
                crop: false,
                style: {
                    fontSize: '0.7rem',
                    textOutline: '1px white',
                }
            },
            labels: [{}],
        }
        ]
    });

    function mergeMinMax() {
        if (max.length > 0 && min.length > 0) {
            let data = [];
            const pointCount = max.length > min.length ? max.length : min.length;
            for (var i = 0; i < pointCount; i++) {
                data.push([min[i], max[i]]);
            }
            chart.series[0].setData(data);
        }
    }

    return {
        chart,
        min(m) {
            chart.series[0].update({
                pointInterval: m.Interval * 1000,
                pointStart: Moment(m.StartDate).valueOf(),
                type: "columnrange",
            });
            min = m.Values;
            mergeMinMax();
        },
        max(m) {
            chart.series[0].update({
                pointInterval: m.Interval * 1000,
                pointStart: Moment(m.StartDate).valueOf(),
                type: "columnrange",
            });
            max = m.Values;
            mergeMinMax();
        },
        avg(m) {
            chart.series[0].setData(m.Values);
            chart.series[0].update({
                pointInterval: m.Interval * 1000,
                pointStart: Moment(m.StartDate).valueOf(),
                type: "spline",
            });
        },
        low_labels: (m) => {
            if (!m) {
                return;
            }

            const ts = Moment(m.Timestamp);
            const toTrunc = ts.valueOf() % chart.series[0].userOptions.pointInterval;
            const startOf = Moment(ts.valueOf() - toTrunc);

            const x = startOf.valueOf();
            const y = numeric(m.Value, 3, m.AccuracyDecimals);

            chart.update({
                annotations: [{
                    labels: [{
                        allowOverlap: true,
                        useHTML: true,
                        text: `L: ${numeric(m.Value, 3, m.AccuracyDecimals)}ft<br>@ ${rangeAppropriateDate(m.Timestamp)}`,
                        point: {
                            x,
                            y,
                            xAxis: 0,
                            yAxis: 0
                        },
                        x: -30,
                        y: 32,
                    },],
                },
                {}
                ]
            })
        },
        high_labels: (m) => {
            if (!m) {
                return;
            }

            const ts = Moment(m.Timestamp);
            const toTrunc = ts.valueOf() % chart.series[0].userOptions.pointInterval;
            const startOf = Moment(ts.valueOf() - toTrunc);

            const x = startOf.valueOf();
            const y = numeric(m.Value, 3, m.AccuracyDecimals);

            chart.update({
                annotations: [
                    {}, {
                        labels: [{
                            allowOverlap: true,
                            useHTML: true,
                            text: `H: ${numeric(m.Value, 3, m.AccuracyDecimals)}ft<br>@ ${rangeAppropriateDate(m.Timestamp)}`,
                            point: {
                                x,
                                y,
                                xAxis: 0,
                                yAxis: 0
                            },
                            x: -30,
                            y: 32,
                        },],
                    },
                ]
            })
        }
    };
}
import Highcharts from "highcharts";

function windSpeedToSpeedTier(speed) {
    if (speed < 2) {
        return "0-2mph"
    } else if (speed < 4) {
        return "2-4mph"
    } else if (speed < 6) {
        return "4-6mph"
    } else if (speed < 8) {
        return "6-8mph"
    } else if (speed < 10) {
        return "8-10mph"
    } else if (speed < 20) {
        return "10-20mph"
    } else {
        return ">20mph"
    }
}

const windSpeedTiers = ["0-2mph", "2-4mph", "4-6mph", "6-8mph", "8-10mph", "10-20mph", ">20mph"]
const cardinalDirections = [
    'N',
    'NNE',
    'NE',
    'ENE',
    'E',
    'ESE',
    'SE',
    'SSE',
    'S',
    'SSW',
    'SW',
    'WSW',
    'W',
    'WNW',
    'NW',
    'NNW',
];

function makeSeries(windDirs, windSpeeds) {
    const windRoseSeries = [];

    // merge wind rose data
    const windDirSpeeds = {};
    if (windDirs.length !== windSpeeds.length) {
        console.warn("wind direction & wind speed have different data point lengths", windDirs.length, windSpeeds.length);
    }

    // loop through list of windDirs, merging with windSpeeds
    for (let i = 0; i < windDirs.length; i++) {
        if (windSpeeds[i] === 0 || windSpeeds[i] == "0.0") {
            continue
        }

        // use windSpeed to match to a tiar
        const tier = windSpeedToSpeedTier(windSpeeds[i]);
        const dir = windDirs[i];

        // build a map of speed tier->cardinalDir->count
        if (!windDirSpeeds[tier]) {
            windDirSpeeds[tier] = {};
        }
        if (!windDirSpeeds[tier][dir]) {
            windDirSpeeds[tier][dir] = 0
        }
        windDirSpeeds[tier][dir]++;
    }

    windSpeedTiers.forEach((tier) => {
        const tierDirs = windDirSpeeds[tier] || {};

        const tierData = {};
        cardinalDirections.forEach(dir => {
            const count = tierDirs[dir] || 0;
            tierData[dir] = parseFloat((count / windDirs.length * 100).toFixed(2));
        });

        windRoseSeries.push({
            name: tier,
            data: [
                ['N', tierData.N],
                ['NNE', tierData.NNE],
                ['NE', tierData.NE],
                ['ENE', tierData.ENE],
                ['E', tierData.E],
                ['ESE', tierData.ESE],
                ['SE', tierData.SE],
                ['SSE', tierData.SSE],
                ['S', tierData.S],
                ['SSW', tierData.SSW],
                ['SW', tierData.SW],
                ['WSW', tierData.WSW],
                ['W', tierData.W],
                ['WNW', tierData.WNW],
                ['NW', tierData.NW],
                ['NNW', tierData.NNW],
            ],
        })
    })

    return windRoseSeries;
}

export default function () {
    var windDirs = [];
    var windSpeeds = [];

    const series = makeSeries(windDirs, windSpeeds);
    const chart = Highcharts.chart('windrose-chart', {
        series,
        credits: {
            enabled: false
        },

        chart: {
            polar: true,
            type: 'column',
            styledMode: true
        },

        title: {
            text: 'Wind Rose',
            align: 'left'
        },

        // subtitle: {
        //   text: '',
        //   align: 'left'
        // },

        pane: {
            size: '85%'
        },

        legend: {
            align: 'right',
            verticalAlign: 'top',
            //y: 100,
            layout: 'vertical'
        },

        xAxis: {
            tickmarkPlacement: 'on',
            categories: cardinalDirections,
        },

        yAxis: {
            min: 0,
            endOnTick: false,
            showLastLabel: true,
            title: {
                text: 'Frequency (%)'
            },
            reversedStacks: false
        },

        tooltip: {
            valueSuffix: '%'
        },

        plotOptions: {
            series: {
                stacking: 'normal',
                shadow: false,
                groupPadding: 0,
                pointPlacement: 'on'
            }
        }
    });
    return {
        chart,
        wind_dirs(m) {
            windDirs = m.Values || [];
            chart.update({ series: makeSeries(windDirs, windSpeeds) });
        },
        wind_speeds(m) {
            windSpeeds = m.Values || [];
            chart.update({ series: makeSeries(windDirs, windSpeeds) });
        },
    };
}
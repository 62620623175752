<template>
    <div class="block sun-block">
        <div class="sun-block-features">
            <div class="sunrise">
                <div class="label">
                    <font-awesome-icon icon="fa-solid fa-sunrise" />
                </div>
                <div class="val"><span class="mono">{{ secondsReltoDate(sunriseRel?.EffectiveEvent?.Value) }}</span></div>
            </div>
            <div class="length">
                <div class="label">
                    <font-awesome-icon icon="fa-regular fa-arrows-left-right-to-line" />
                </div>
                <div class="val"><span class="mono">{{ secondsReltoDuration(dayLength?.EffectiveEvent?.Value) }}</span>
                </div>
            </div>
            <div class="sunset">
                <div class="label">
                    <font-awesome-icon icon="fa-solid fa-sunset" />
                </div>
                <div class="val"><span class="mono">{{ secondsReltoDate(sunsetRel?.EffectiveEvent?.Value) }}</span></div>
            </div>
        </div>
    </div>
</template>
<script>
import moment from 'moment';
export default {
    name: 'SunBlock',
    props: ['sunriseRel', 'sunsetRel', 'dayLength'],
    async setup() {
        return {
            secondsReltoDate: (s) => moment().startOf('day').add(s, 'seconds').format("h:mm A"),
            secondsReltoDuration: (d) => {
                const dur = moment.duration(d, 'seconds');
                return `${dur.hours()}h ${dur.minutes()}m`;
            }
        };
    },
}
</script>
<style lang="scss">
.weather {
    .blocks {
        .sun-block {
            display: flex;
            align-items: center;

            .sun-block-features {
                width: 100%;
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                grid-template-rows: 1fr;
                grid-column-gap: 0px;
                grid-row-gap: 0px;
            }

            .sunrise,
            .sunset,
            .length {
                text-align: center;
            }
        }
    }

    &.weather--kioskmode {
        .blocks {
            .sun-block {}
        }
    }
}
</style>
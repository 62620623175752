import Highcharts from "highcharts";
import Moment from "moment";
import { intervalAppropriateDate, numeric } from "../../util";

export default function () {
    const chart = Highcharts.chart('barometer-chart', {
        chart: {
            type: 'spline',
            scrollablePlotArea: {
                // minWidth: 600,
                scrollPositionX: 1
            },
            styledMode: true
        },
        credits: {
            enabled: false
        },
        title: {
            text: '',
            align: 'left'
        },
        subtitle: {
            text: '',
            align: 'left'
        },
        xAxis: {
            type: 'datetime',
            labels: {
                overflow: 'justify',
                formatter() {
                    return intervalAppropriateDate(this.value, this.axis.series[0].options.pointInterval);
                },
            },
            plotBands: [{
                color: '#ffdab9',
                from: Moment('Jan 7, 2022 8:00 AM'),
                to: Moment('Feb 4, 2022 5:00 AM'),
                label: {
                    text: 'Station Offline',
                }
            }],
        },
        yAxis: {
            title: {
                text: 'inHg'
            },
            minorGridLineWidth: 0,
            gridLineWidth: 0,
            alternateGridColor: null,
        },
        tooltip: {
            formatter() {
                const ts = intervalAppropriateDate(this.x, this.series.options.pointInterval);
                return `${ts}<br>${this.series.name} <b>${numeric(this.y, 2, 2)} inHg</b>`;
            }
        },
        plotOptions: {
            spline: {
                lineWidth: 4,
                states: {
                    hover: {
                        lineWidth: 5
                    }
                },
                marker: {
                    enabled: false
                },
            }
        },
        series: [{
            name: "Barometric Pressure",
            data: [],
        },],
        navigation: {
            menuItemStyle: {
                fontSize: '10px'
            }
        },
        legend: {
            enabled: false
        }
    });
    return {
        chart,
        avg(m) {
            chart.series[0].setData(m.Values);
            chart.series[0].update({
                pointInterval: m.Interval * 1000,
                pointStart: Moment(m.StartDate).valueOf(),
                type: "spline",
            });
        },
    };
}
import Highcharts from "highcharts";
import Moment from "moment";
import { intervalAppropriateDate, numeric } from "../../util";

const determineSegmentColor = (num) => {
    let value;
    if (num >= 0 && num < 3) {
        value = "green"; // Low
    } else if (num >= 3 && num < 6) {
        value = "#e0e005"; // Moderate 
    } else if (num >= 6 && num < 8) {
        value = "orange"; // High
    } else if (num >= 8 && num < 11) {
        value = "red"; // Very High
    } else if (num >= 11) {
        value = "purple"; // Extreme
    } else {
        value = "gray"; // Unknown
    }
    return value;
}


export default function (el) {
    const chart = Highcharts.chart(el, {
        chart: {
            type: 'spline',
            scrollablePlotArea: {
                // minWidth: 600,
                scrollPositionX: 1
            },
            styledMode: true
        },
        credits: {
            enabled: false
        },
        title: {
            text: '',
            align: 'left'
        },
        subtitle: {
            text: '',
            align: 'left'
        },
        xAxis: {
            type: 'datetime',
            labels: {
                overflow: 'justify',
                formatter() {
                    return intervalAppropriateDate(this.value, this.axis.series[0].options.pointInterval);
                },
            },
            plotBands: [],
        },
        yAxis: {
            title: {
                text: 'UV Index'
            },
            minorGridLineWidth: 0,
            gridLineWidth: 0,
            alternateGridColor: null,
        },
        tooltip: {
            formatter() {
                const ts = intervalAppropriateDate(this.x, this.series.options.pointInterval);
                return `${ts}<br>${this.series.name} <b>${numeric(this.y, 3, 0)} UV Index</b>`;
            }
        },
        plotOptions: {
            spline: {
                lineWidth: 4,
                states: {
                    hover: {
                        lineWidth: 5
                    }
                },
                marker: {
                    enabled: false
                },
            }
        },
        series: [{
            name: "UV Index",
            data: [],
        },],
        navigation: {
            menuItemStyle: {
                fontSize: '10px'
            }
        },
        legend: {
            enabled: false
        }
    });
    return {
        chart,
        avg(m) {
            // colored bands
            const colored = m.Values?.map(num => ({
                y: num,
                segmentColor: determineSegmentColor(num) // Use a function to determine the segmentColor based on the number
            }));

            chart.series[0].setData(colored);
            chart.series[0].update({
                pointInterval: m.Interval * 1000,
                pointStart: Moment(m.StartDate).valueOf(),
                type: "coloredline",
                turboThreshold: 2000,
            });
        },
    };
}
import axios from "axios";

export default class HttpClient {
    constructor(url) {
        this.http = axios.create({
            baseURL: url,
            withCredentials: false,
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        });
    }

    getCurrent() {
        return this.http.get(`current`).then(res => {
            return res.data;
        })
    }
    requestRecords() {
        return this.$axios.get(`records?start=${this.startDate}&end=${this.endDate}`).then(res => {
            this.records = res.data;
            return this.records;
        })
    }
    requestForecasts() {
        return this.$axios.get(`forecasts?start=${this.startDateForecast}&end=${this.endDateForecast}`).then(res => {
            this.forecasts = res.data;
            return this.forecasts;
        })
    }
}
<template>
    <div class="block uv-block">
        <h3 class="block-header">UV Index</h3>
        <div class="uv-block-features">
            <div class="primary-metric"
                :class="{ ['uv-index-desc--' + uvIndexDesc?.State?.replace(/\s+/g, '-').toLowerCase()]: true }">
                <div class="circle-container">
                    <div class="val primary"><span class="mono">{{ numeric(uvIndex?.State, 1, uvIndex?.AccuracyDecimals ||
                        0) }}</span></div>
                    <div class="label">{{ uvIndexDesc?.State }}</div>
                </div>
            </div>
            <div class="uv-chart">
                <figure id="uv-chart"></figure>
                <div class="label">{{ uvIndexDesc?.State }}</div>
            </div>
        </div>
    </div>
</template>
<script>
import { numeric } from "../util";

export default {
    name: 'UVBlock',
    props: ['uvIndex', 'uvIndexDesc'],
    async setup() {
        return {
            numeric,
        };
    },
}
</script>
<style lang="scss">
.weather {
    .blocks {
        .uv-block {
            .uv-block-features {
                display: grid;
                grid-template-columns: 400px 1fr;
                grid-template-rows: 1fr;
                grid-column-gap: 0px;
                grid-row-gap: 0px;

                .primary-metric {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    border-radius: 50%;
                    text-align: center;

                    &.uv-index-desc--low {
                        .circle-container {
                            background-color: #00ff00;
                        }
                    }

                    &.uv-index-desc--moderate {
                        .circle-container {
                            background-color: #e0e005;
                        }
                    }

                    &.uv-index-desc--high {
                        .circle-container {
                            background-color: #e19509;
                        }
                    }

                    &.uv-index-desc--very-high {
                        .circle-container {
                            background-color: #a20000;
                            color: black;
                        }
                    }

                    &.uv-index-desc--extreme {
                        .circle-container {
                            background-color: #8f3f97;
                        }
                    }
                }

                .circle-container {
                    width: 200px;
                    height: 200px;
                    border-radius: 50%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                }


                .uv-chart {
                    .label {
                        display: none;
                    }

                    min-height: 400px;
                    max-height: 400px;
                }

                @media only screen and (max-width: 1400px) {
                    grid-template-columns: 300px 1fr;

                    .uv-chart {
                        min-height: 200px;
                    }
                }

                @media only screen and (max-width: 600px) {
                    grid-template-columns: 1fr;
                    grid-template-rows: 150px 1fr;

                    .circle-container {
                        width: 150px;
                        height: 150px;
                    }

                    .uv-chart {
                        grid-column: 1 / span 1;
                    }
                }
            }
        }
    }

    &.weather--kioskmode {
        .blocks {
            .uv-block {
                .uv-block-features {
                    grid-template-columns: 200px 1fr;

                    .primary-metric {
                        height: 200px;
                    }

                    .uv-chart {
                        max-height: 200px;
                        min-height: 200px;
                        height: 200px;

                        figure {
                            margin-top: -2rem;
                        }
                    }

                    // @media only screen and (max-width: 1200px) {
                    //     grid-template-columns: 220px 1fr;
                    // }

                    // kindle fire
                    @media only screen and (max-height: 1280px) {

                        grid-template-columns: 1fr 1fr;

                        .primary-metric {
                            height: 75px;

                            .circle-container {
                                width: 75px;
                                height: 75px;

                                .val.primary {
                                    font-size: 1.25rem;
                                }

                                .label {
                                    display: none;
                                }
                            }

                        }

                        .uv-chart {
                            max-height: 75px;
                            min-height: 75px;
                            height: 75px;

                            display: flex;
                            justify-content: center;
                            align-items: center;

                            .label {
                                display: block;
                                font-size: 1.2rem;
                            }

                            figure {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>
<template>
    <div class="block barometer-block">
        <h3 class="block-header">Barometer</h3>
        <div class="barometer-block-features">
            <div class="primary-metric">
                <div class="val primary"><span class="mono">{{ numeric(barAbsolute?.State, 2, barAbsolute?.AccuracyDecimals
                    || 0) }}</span> <span class="unit">inHg</span></div>
                <div class="label">{{ barTrendDesc?.State }}</div>
            </div>
            <div class="barometer-chart">
                <figure id="barometer-chart"></figure>
            </div>
        </div>
    </div>
</template>
<script>
import { numeric } from "../util";

export default {
    name: 'BarometerBlock',
    props: ['barAbsolute', 'barTrendDesc'],
    async setup() {
        return {
            numeric,
        };
    },
}
</script>
<style lang="scss">
.weather {
    .blocks {
        .barometer-block {
            .barometer-block-features {
                display: grid;
                grid-template-columns: 400px 1fr;
                grid-template-rows: 1fr;
                grid-column-gap: 0px;
                grid-row-gap: 0px;

                .primary-metric {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                }

                .barometer-chart {
                    min-height: 400px;
                    max-height: 400px;
                }

                @media only screen and (max-width: 1400px) {
                    grid-template-columns: 300px 1fr;

                    .barometer-chart {
                        min-height: 200px;
                        max-height: 200px;
                    }
                }

                @media only screen and (max-width: 600px) {
                    grid-template-columns: 1fr;
                    grid-template-rows: 100px 1fr;

                    .barometer-chart {
                        grid-column: 1 / span 1;
                    }
                }
            }
        }
    }

    &.weather--kioskmode {
        .blocks {
            .barometer-block {
                .barometer-block-features {
                    .barometer-chart {
                        min-height: 200px;
                        max-height: 200px;
                    }

                    @media only screen and (max-width: 1200px) {
                        grid-template-columns: 220px 1fr;
                    }

                    @media only screen and (max-height: 1280px) {
                        .barometer-chart {
                            min-height: 150px;
                        }
                    }
                }
            }
        }
    }
}
</style>
import { ref } from 'vue';

export default function createTextForecast({ ID, Name, HorizonActual, HorizonTarget, EffectiveEvent, NextEvents }) {
    const sensor = {
        ID: ref(ID),
        Name: ref(Name),
        HorizonTarget: ref(HorizonTarget),
        HorizonActual: ref(HorizonActual),
        EffectiveEvent: ref(EffectiveEvent),
        NextEvents: ref(NextEvents),
        valuesFromSource({ Name, HorizonActual, HorizonTarget, EffectiveEvent, NextEvents }) {
            sensor.Name.value = Name;
            sensor.HorizonActual.value = HorizonActual;
            sensor.HorizonTarget.value = HorizonTarget;
            sensor.EffectiveEvent.value = EffectiveEvent;
            sensor.NextEvents.value = NextEvents;
        },
        updateEvents({ HorizonActual, EffectiveEvent, NextEvents }) {
            sensor.HorizonActual.value = HorizonActual;
            sensor.EffectiveEvent.value = EffectiveEvent;
            sensor.NextEvents.value = NextEvents;
        }
    };

    return sensor;
}
import Highcharts from "highcharts";

export default function () {
    const chart = Highcharts.chart('rainfall-chart', {
        chart: {
            type: 'column',
            styledMode: true
        },
        credits: {
            enabled: false
        },
        title: {
            text: 'Cumulative Rainfall',
            align: 'left'
        },
        subtitle: {},
        xAxis: {
            labels: {
                rotation: 0,
            },
            categories: [
                'Last 60m',
                'Last 24h',
                'This Month',
                'This Year',
            ]
        },
        legend: {
            enabled: false
        },
        yAxis: {
            min: 0,
            title: {
                text: 'Rainfall (in)'
            }
        },
        tooltip: {
            valueSuffix: ' in'
        },
        plotOptions: {
            column: {
                pointPadding: 0.2,
                borderWidth: 0
            }
        },
        series: [{
            name: "Rainfall",
            dataLabels: {
                enabled: true,
            },
            data: [
                ["Last 60m", 0],
                ["Last 24h", 0],
                ["This Month", 0],
                ["This Year", 0]
            ]
        },]
    });

    return {
        chart,
        total_60m(m) {
            chart.series[0].setData([
                ["Last 60m", Math.round((m.State) * 100) / 100],
                ["Last 24h", chart.series[0].data[1].y],
                ["This Month", chart.series[0].data[2].y],
                ["This Year", chart.series[0].data[3].y]
            ]);
        },
        total_24h(m) {
            chart.series[0].setData([
                ["Last 60m", chart.series[0].data[0].y],
                ["Last 24h", Math.round((m.State) * 100) / 100],
                ["This Month", chart.series[0].data[2].y],
                ["This Year", chart.series[0].data[3].y]
            ]);
        },
        total_month(m) {
            chart.series[0].setData([
                ["Last 60m", chart.series[0].data[0].y],
                ["Last 24h", chart.series[0].data[1].y],
                ["This Month", Math.round((m.State) * 100) / 100],
                ["This Year", chart.series[0].data[3].y]
            ]);
        },
        total_year(m) {
            chart.series[0].setData([
                ["Last 60m", chart.series[0].data[0].y],
                ["Last 24h", chart.series[0].data[1].y],
                ["This Month", chart.series[0].data[2].y],
                ["This Year", Math.round((m.State) * 100) / 100],
            ]);
        },
    };
}
<template>
    <div v-if="error"> {{ error.message }}</div>
    <Suspense v-else>
        <router-view/>
    </Suspense>
</template>
<script>
import { defineComponent, ref, onErrorCaptured } from 'vue'

export default defineComponent({
    setup() {
        const error = ref(null);
        onErrorCaptured(caughtError => {
            error.value = caughtError;
            return true;
        })
        return { error };
    }
})
</script>
<style>
#app {}
</style>
import { reactive, ref, computed } from 'vue';
import $config from "./config";

export function useChartManager(state, viewWindowMode) {
    const charts = reactive({});
    const dependencyTree = ref({});


    const chart_pointInterval = computed(() => {
        switch (viewWindowMode.value) {
            case "YEAR":
                return 1000 * 60 * 60 * 24;
            case "MONTH":
                return 1000 * 60 * 60;
            case "WEEK":
                return 1000 * 60 * 5;
            default:
                return 1000 * 60;
        }
    })

    const registerChart = (id, chartInstance) => {
        charts[id] = chartInstance;
    };

    // build a 3d index for mapping metric ID to chart and chart parameter, for the current viewWindowMode
    // eg. {temp_low_day: {temp: ["min_label_temp"]}}
    // where "temp_low_day" is a metricID, "temp" is a chartID, and "min_label_temp" is a chart parameter
    const rebuildChartDependencies = () => {
        const deps = {
            sensors: {},
            textWindowAggregations: {},
            windowAggregations: {},
            periodAggregations: {},
        };
        for (const [chartId, chartModes] of Object.entries($config.chartParamMap)) {
            const chart_def = chartModes?.[viewWindowMode.value] || chartModes?.["ALL"];
            if (!chart_def) {
                console.warn(`chart "${chartId}" not configured for ${viewWindowMode.value} view`);
                continue;
            }
            for (const [entityType, chartParams] of Object.entries(chart_def)) {
                for (const [chartParam, entityId] of Object.entries(chartParams)) {
                    if (!deps[entityType][entityId]) {
                        deps[entityType][entityId] = {};
                    }
                    if (!deps[entityType][entityId][chartId]) {
                        deps[entityType][entityId][chartId] = [];
                    }
                    deps[entityType][entityId][chartId].push(chartParam);
                }
            }
        }
        console.log(`updated chart dependencies map for view window ${viewWindowMode.value}`, deps);
        dependencyTree.value = deps;
        return deps;
    };

    // updates the relevant chart for each sensor change
    function updateChartsForEntity(entityType, entityID) {
        const deps = dependencyTree.value[entityType]?.[entityID];
        if (deps) {
            //console.log(`updating charts for ${entityID}:`, deps);

            // get the actual entity
            var entity;
            switch (entityType) {
                case "sensors":
                    entity = state.sensors[entityID];
                    break;
                case "textSensors":
                    entity = state.textSensors[entityID];
                    break;
                case "forecasts":
                    entity = state.forecasts[entityID];
                    break;
                case "textForecasts":
                    entity = state.textForecasts[entityID];
                    break;
                case "windowAggregations":
                    entity = state.windowAggregations[entityID];
                    break;
                case "textWindowAggregations":
                    entity = state.textWindowAggregations[entityID];
                    break;
                case "periodAggregations":
                    entity = state.periodAggregations[entityID];
                    break;
                default:
                    console.error(`unknown entity type: ${entityType}`);
                    return;
            }
            if (!entity) {
                console.warn(`entity not found: ${entityType} ${entityID}`);
                return;
            }

            for (const [chartId, chartParams] of Object.entries(deps)) {
                if (!charts[chartId]) {
                    console.warn(`chart not initialized: ${chartId}`);
                    continue;
                }

                chartParams.forEach((paramName) => {
                    if (!(charts[chartId][paramName] instanceof Function)) {
                        console.warn(`encountered unsupported chart param name=${paramName} chart=${chartId}`);
                        return;
                    }
                    console.log(`updating chart "${chartId}" param "${paramName}" with entity "${entityID}"`);
                    charts[chartId][paramName](entity);
                });
            }
        }
    }

    // for each chart, uses the dependency index (chart_sensor_deps)
    // to pull the latest data from the main dataSource object, and update the chart
    function rebuildCharts() {
        console.log("rebuilding charts");

        // set plot opts for each chart
        for (const [chartId, chart] of Object.entries(charts)) {
            if (!(chart.updatePlotOpts instanceof Function)) {
                console.warn(`chart "${chartId}" does not support updatePlotOpts`);
                continue;
            }
            console.log(`updating plot options for chart "${chartId}"`)
            chart.updatePlotOpts(state.startDate, chart_pointInterval.value);
        }


        // run through all dependencies, and update charts for each
        for (const [entityType, entities] of Object.entries(dependencyTree.value)) {
            for (const [entityId] of Object.entries(entities)) {
                updateChartsForEntity(entityType, entityId);
            }
        }
    }

    return {
        charts,
        registerChart,
        rebuildChartDependencies,
        updateChartsForEntity,
        rebuildCharts,
    };
}

module.exports = {
  stationid: "lemolo",
  title: "Lemolo Weather",
  subtitle: "Current Conditions for Lemolo/Nesika Bay, Washington",
  stationCoordinates: `47°42'42"N 122°37'12"W`,
  apiUrl: 'https://api.lemoloweather.com/v1/',
  websocketUrl: 'wss://api.lemoloweather.com/v1/ws',
  watchEntities: {
    sensors: [
      "temp",
      "heat_index",
      "hum",
      "wind_chill",
      "dew_point",
      "wet_bulb",
      "rain_rate_last",
      "wind_speed_last",
      "wind_speed_avg_last_10_min",
      "wind_speed_hi_last_10_min",
      "bar_absolute",
      "nowcast_aqi",
      "uv_index",
      "rainfall_last_60_min",
      "rainfall_last_24_hr",
      "rainfall_month",
      "rainfall_year",
    ],
    textSensors: [
      "wind_dir_cardinal_last",
      "wind_dir_cardinal_avg_last_10_min",
      "wind_dir_cardinal_at_hi_speed_last_10_min",
      "bar_trend_desc",
      "nowcast_aqi_cat",
      "uv_index_desc",
    ],
    forecasts: [
      "tide_pred",
      "tide_pred_high",
      "tide_pred_low",
      "sunrise_rel",
      "sunset_rel",
      "day_length",
    ],
    textForecasts: [
      "tide_pred_direction",
      "nws_synopsis",
      "nws_alert",
    ],
    windowAggregations: [
      "temp_avg_day",
      // "temp_avg_week",
      // "temp_avg_month",
      // "temp_avg_year",
      "heat_index_avg_day",
      "heat_index_avg_week",
      "heat_index_avg_month",
      "heat_index_avg_year",
      "hum_avg_day",
      "hum_avg_week",
      "hum_avg_month",
      "hum_avg_year",
      //"temp_min_day",
      "temp_min_week",
      "temp_min_month",
      "temp_min_year",
      //"temp_max_day",
      "temp_max_week",
      "temp_max_month",
      "temp_max_year",
      //"hum_min_day",
      "hum_min_week",
      "hum_min_month",
      "hum_min_year",
      //"hum_max_day",
      "hum_max_week",
      "hum_max_month",
      "hum_max_year",
      "rain_rate_hi_last_15_min_avg_day",
      "rain_rate_hi_last_15_min_avg_week",
      "rain_rate_hi_last_15_min_avg_month",
      "rain_rate_hi_last_15_min_avg_year",
      "wind_speed_avg_last_1_min_avg_day",
      "wind_speed_avg_last_1_min_avg_week",
      "wind_speed_avg_last_1_min_avg_month",
      "wind_speed_avg_last_1_min_avg_year",
      "wind_speed_hi_last_2_min_max_day",
      "wind_speed_hi_last_2_min_max_week",
      "wind_speed_hi_last_2_min_max_month",
      "wind_speed_hi_last_2_min_max_year",
      "bar_absolute_avg_day",
      "bar_absolute_avg_week",
      "bar_absolute_avg_month",
      "bar_absolute_avg_year",
      "nowcast_aqi_avg_day",
      "nowcast_aqi_avg_week",
      "nowcast_aqi_avg_month",
      "nowcast_aqi_avg_year",
      "uv_index_avg_day",
      "uv_index_avg_week",
      "uv_index_avg_month",
      "uv_index_avg_year",
      "tide_pred_max_day",
      "tide_pred_max_week",
      "tide_pred_max_month",
      "tide_pred_max_year",
    ],
    textWindowAggregations: [
      "wind_dir_cardinal_avg_last_1_min_avg_day",
      "wind_dir_cardinal_avg_last_1_min_avg_week",
      "wind_dir_cardinal_avg_last_1_min_avg_month",
      "wind_dir_cardinal_avg_last_1_min_avg_year",
    ],
    periodAggregations: [
      "temp_day_max",
      "temp_week_max",
      "temp_month_max",
      "temp_year_max",
      "temp_day_min",
      "temp_week_min",
      "temp_month_min",
      "temp_year_min",
      "rain_rate_hi_last_15_min_day_max",
      "rain_rate_hi_last_15_min_week_max",
      "rain_rate_hi_last_15_min_month_max",
      "rain_rate_hi_last_15_min_year_max",
      "wind_speed_hi_last_2_min_day_max",
      "wind_speed_hi_last_2_min_week_max",
      "wind_speed_hi_last_2_min_month_max",
      "wind_speed_hi_last_2_min_year_max",
      "tide_pred_day_max",
      "tide_pred_day_min",
    ]
  },
  metricAggrMap: {
    temp_high: {
      DAY: "temp_day_max",
      WEEK: "temp_week_max",
      MONTH: "temp_month_max",
      YEAR: "temp_year_max",
    },
    temp_low: {
      DAY: "temp_day_min",
      WEEK: "temp_week_min",
      MONTH: "temp_month_min",
      YEAR: "temp_year_min",
    },
  },
  chartParamMap: {
    temp: {
      DAY: {
        windowAggregations: {
          avg_temp: "temp_avg_day",
          avg_heatindex: "heat_index_avg_day",
          avg_hum: "hum_avg_day",
        },
        periodAggregations: {
          max_label_temp: "temp_day_max",
          min_label_temp: "temp_day_min",
        }
      },
      WEEK: {
        windowAggregations: {
          avg_heatindex: "heat_index_avg_week",
          avg_hum: "hum_avg_week",
          min_temp: "temp_min_week",
          max_temp: "temp_max_week",
          min_hum: "hum_min_week",
          max_hum: "hum_max_week",
        },
        periodAggregations: {
          max_label_temp: "temp_week_max",
          min_label_temp: "temp_week_min",
        }
      },
      MONTH: {
        windowAggregations: {
          avg_heatindex: "heat_index_avg_month",
          avg_hum: "hum_avg_month",
          min_temp: "temp_min_month",
          max_temp: "temp_max_month",
          min_hum: "hum_min_month",
          max_hum: "hum_max_month",
        },
        periodAggregations: {
          max_label_temp: "temp_month_max",
          min_label_temp: "temp_month_min",
        }
      },
      YEAR: {
        windowAggregations: {
          avg_heatindex: "heat_index_avg_year",
          avg_hum: "hum_avg_year",
          min_temp: "temp_min_year",
          max_temp: "temp_max_year",
          min_hum: "hum_min_year",
          max_hum: "hum_max_year",
        },
        periodAggregations: {
          max_label_temp: "temp_year_max",
          min_label_temp: "temp_year_min",
        }
      },
    },
    rainrate: {
      DAY: {
        windowAggregations: {
          avg: "rain_rate_hi_last_15_min_avg_day",
        },
        periodAggregations: {
          max_label: "rain_rate_hi_last_15_min_day_max"
        }
      },
      WEEK: {
        windowAggregations: {
          avg: "rain_rate_hi_last_15_min_avg_week",
        },
        periodAggregations: {
          max_label: "rain_rate_hi_last_15_min_week_max"
        }
      },
      MONTH: {
        windowAggregations: {
          avg: "rain_rate_hi_last_15_min_avg_month",
        },
        periodAggregations: {
          max_label: "rain_rate_hi_last_15_min_month_max"
        }
      },
      YEAR: {
        windowAggregations: {
          avg: "rain_rate_hi_last_15_min_avg_year",
        },
        periodAggregations: {
          max_label: "rain_rate_hi_last_15_min_year_max"
        }
      },
    },
    rainfall: {
      ALL: {
        sensors: {
          total_60m: "rainfall_last_60_min",
          total_24h: "rainfall_last_24_hr",
          total_month: "rainfall_month",
          total_year: "rainfall_year",
        },
      }
    },
    wind: {
      DAY: {
        windowAggregations: {
          avg: "wind_speed_avg_last_1_min_avg_day",
          gust: "wind_speed_hi_last_2_min_max_day",
        },
        periodAggregations: {
          max_label: "wind_speed_hi_last_2_min_day_max",
        },
      },
      WEEK: {
        windowAggregations: {
          avg: "wind_speed_avg_last_1_min_avg_week",
          gust: "wind_speed_hi_last_2_min_max_week",
        },
        periodAggregations: {
          max_label: "wind_speed_hi_last_2_min_week_max",
        },
      },
      MONTH: {
        windowAggregations: {
          avg: "wind_speed_avg_last_1_min_avg_month",
          gust: "wind_speed_hi_last_2_min_max_month",
        },
        periodAggregations: {
          max_label: "wind_speed_hi_last_2_min_month_max",
        },
      },
      YEAR: {
        windowAggregations: {
          avg: "wind_speed_avg_last_1_min_avg_year",
          gust: "wind_speed_hi_last_2_min_max_year",
        },
        periodAggregations: {
          max_label: "wind_speed_hi_last_2_min_year_max",
        },
      },
    },
    windrose: {
      DAY: {
        textWindowAggregations: {
          wind_dirs: "wind_dir_cardinal_avg_last_1_min_avg_day",
        },
        windowAggregations: {
          wind_speeds: "wind_speed_avg_last_1_min_avg_day",
        },
      },
      WEEK: {
        textWindowAggregations: {
          wind_dirs: "wind_dir_cardinal_avg_last_1_min_avg_week",
        },
        windowAggregations: {
          wind_speeds: "wind_speed_avg_last_1_min_avg_week",
        },
      },
      MONTH: {
        textWindowAggregations: {
          wind_dirs: "wind_dir_cardinal_avg_last_1_min_avg_month",
        },
        windowAggregations: {
          wind_speeds: "wind_speed_avg_last_1_min_avg_month",
        },
      },
      YEAR: {
        textWindowAggregations: {
          wind_dirs: "wind_dir_cardinal_avg_last_1_min_avg_year",
        },
        windowAggregations: {
          wind_speeds: "wind_speed_avg_last_1_min_avg_year",
        },
      },
    },
    aqi: {
      DAY: {
        windowAggregations: {
          avg: "nowcast_aqi_avg_day",
        },
      },
      WEEK: {
        windowAggregations: {
          avg: "nowcast_aqi_avg_week",
        },
      },
      MONTH: {
        windowAggregations: {
          avg: "nowcast_aqi_avg_month",
        },
      },
      YEAR: {
        windowAggregations: {
          avg: "nowcast_aqi_avg_year",
        },
      },
    },
    uv: {
      DAY: {
        windowAggregations: {
          avg: "uv_index_avg_day",
        },
      },
      WEEK: {
        windowAggregations: {
          avg: "uv_index_avg_week",
        },
      },
      MONTH: {
        windowAggregations: {
          avg: "uv_index_avg_month",
        },
      },
      YEAR: {
        windowAggregations: {
          avg: "uv_index_avg_year",
        },
      },
    },
    bar: {
      DAY: {
        windowAggregations: {
          avg: "bar_absolute_avg_day",
        },
      },
      WEEK: {
        windowAggregations: {
          avg: "bar_absolute_avg_week",
        },
      },
      MONTH: {
        windowAggregations: {
          avg: "bar_absolute_avg_month",
        },
      },
      YEAR: {
        windowAggregations: {
          avg: "bar_absolute_avg_year",
        },
      },
    },
    tide: {
      DAY: {
        windowAggregations: {
          avg: "tide_pred_max_day",
        },
        periodAggregations: {
          high_labels: "tide_pred_day_max",
          low_labels: "tide_pred_day_min",
        }
      },
      WEEK: {
        windowAggregations: {
          avg: "tide_pred_max_week",
        },
      },
      MONTH: {
        windowAggregations: {
          avg: "tide_pred_max_month",
        },
      },
      YEAR: {
        windowAggregations: {
          avg: "tide_pred_max_year",
        },
      },
    },
  }
}


// if dev mode, override api url
if (process.env.NODE_ENV === 'development') {
  module.exports.apiUrl = 'http://localhost:5001/v1/'
  module.exports.websocketUrl = 'ws://localhost:5001/v1/ws'
}
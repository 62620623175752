<template>
    <div class="block tide-block">
        <h3 class="block-header">Tide</h3>
        <div class="tide-block-features">
            <div class="primary-metric">
                <div class="val primary"><span class="mono">{{ numeric(tidePred?.EffectiveEvent?.Value, 2,
                    tidePred?.AccuracyDecimals)
                }}</span> <span class="unit">ft</span></div>
                <div class="tide__label">{{ tidePredDir?.EffectiveEvent?.Value }}</div>
            </div>
            <div class="detail-table">
                <div class="next-event-row detail-table__row" :style="{ order: nextHighOrder }">
                    <div class="label"><span class="label--long">Next High</span><span class="label--short">H</span> @
                        {{ rangeAppropriateDate(nextHighEvent?.EffectiveFrom) }}</div>
                    <div class="val mono">{{ numeric(nextHighEvent?.Value, 2,
                        tidePredHigh?.AccuracyDecimals || 0) }}
                        ft</div>
                </div>
                <div class="next-event-row detail-table__row" :style="{ order: nextLowOrder }">
                    <div class="label"><span class="label--long">Next Low</span><span class="label--short">L</span> @
                        {{ rangeAppropriateDate(nextLowEvent?.EffectiveFrom) }}</div>
                    <div class="val mono">{{ numeric(nextLowEvent?.Value, 2,
                        tidePredLow?.AccuracyDecimals || 0) }}
                        ft</div>
                </div>
            </div>
            <div class="tide-chart">
                <figure id="tide-chart"></figure>
            </div>
        </div>
    </div>
</template>
<script>
import { numeric } from "../util";
import { computed, toRefs } from "vue";
import moment from "moment";

export default {
    name: 'TideBlock',
    props: ['tidePred', 'tidePredDir', 'tidePredHigh', 'tidePredLow', 'rangeAppropriateDate'],
    async setup(props) {
        const { tidePredHigh, tidePredLow } = toRefs(props);

        const nextHighEvent = computed(() => {
            if (tidePredHigh?.value?.EffectiveEvent == null) {
                return null;
            }

            if (moment(tidePredHigh.value.EffectiveEvent.EffectiveFrom) < moment()) {
                return tidePredHigh.value.NextEvents?.[0];
            } else {
                return tidePredHigh.value.EffectiveEvent;
            }
        });

        const nextLowEvent = computed(() => {
            if (tidePredLow?.value?.EffectiveEvent == null) {
                return null;
            }

            if (moment(tidePredLow.value.EffectiveEvent.EffectiveFrom) < moment()) {
                return tidePredLow.value.NextEvents?.[0];
            } else {
                return tidePredLow.value.EffectiveEvent;
            }
        });

        const nextHighOrder = computed(() => {
            const highDate = new Date(nextHighEvent.value?.EffectiveFrom);
            const lowDate = new Date(nextLowEvent.value?.EffectiveFrom);
            return highDate > lowDate ? 1 : 0;
        });

        const nextLowOrder = computed(() => {
            const highDate = new Date(nextHighEvent.value?.EffectiveFrom);
            const lowDate = new Date(nextLowEvent.value?.EffectiveFrom);
            return highDate > lowDate ? 0 : 1;
        });

        return {
            numeric,
            nextHighEvent,
            nextLowEvent,
            nextHighOrder,
            nextLowOrder,
        };
    },
}
</script>
<style lang="scss">
.weather {
    .blocks {
        .tide-block {
            .tide-block-features {
                display: grid;
                grid-template-columns: 400px 1fr;
                grid-template-rows: repeat(2, 1fr);
                grid-column-gap: 0px;
                grid-row-gap: 0px;

                .primary-metric {
                    grid-area: 1 / 1 / 2 / 2;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;

                    .tide__highlow__row {
                        display: flex;
                        width: 100%;
                        justify-content: space-around;
                        margin: 1rem 0;

                        .tide__highlow.high {
                            color: red;
                        }

                        .tide__highlow.low {
                            color: blue;
                        }
                    }
                }

                .detail-table {
                    padding: 0 0.5rem;
                    grid-area: 2 / 1 / 3 / 2;
                    width: calc(100% - 1rem);
                    display: flex;
                    flex-direction: column;

                    .detail-table__row {
                        display: flex;
                        width: 100%;
                        justify-content: space-between;
                    }

                    .detail-table__row .val {
                        text-align: right;
                    }
                }

                .tide-chart {
                    grid-area: 1 / 2 / 3 / 3;
                    min-height: 400px;
                    max-height: 400px;

                    .highcharts-label--tide-high {
                        color: white;

                        .highcharts-label-box {
                            fill: red;
                        }
                    }

                    .highcharts-label--tide-low {
                        color: white;

                        .highcharts-label-box {
                            fill: blue;
                        }
                    }
                }

                @media only screen and (max-width: 1400px) {
                    grid-template-columns: repeat(2, 1fr);
                    grid-template-rows: 1fr 400px;

                    .primary-metric {
                        grid-area: 1 / 1 / 2 / 2;
                    }

                    .detail-table {
                        grid-area: 1 / 2 / 2 / 3;
                    }

                    .tide-chart {
                        grid-area: 2 / 1 / 3 / 3;
                    }
                }

                @media only screen and (max-width: 600px) {
                    grid-template-columns: 1fr;
                    grid-template-rows: repeat(2, 1fr) 200px;

                    .primary-metric {
                        grid-area: 1 / 1 / 2 / 2;
                    }

                    .detail-table {
                        grid-area: 2 / 1 / 3 / 2;
                    }

                    .tide-chart {
                        grid-area: 3 / 1 / 4 / 2;
                    }
                }

                // iphone
                @media only screen and (max-height: 850px) {

                    // shorten the chart height so it doesn't take up so much of the screen
                    .tide-chart {
                        min-height: 200px;
                        height: 200px;
                        max-height: 200px;
                    }
                }
            }
        }
    }

    &.weather--kioskmode {
        .blocks {
            .tide-block {
                .tide-block-features {
                    grid-template-columns: repeat(2, 1fr);
                    grid-template-rows: 1fr 200px;

                    .primary-metric {
                        grid-area: 1 / 1 / 2 / 2;
                    }

                    .detail-table {
                        grid-area: 1 / 2 / 2 / 3;
                    }

                    .tide-chart {
                        min-height: 300px;
                        grid-area: 2 / 1 / 3 / 3;
                    }

                    @media only screen and (max-width: 1200px) {
                        grid-template-columns: 1fr;
                        grid-template-rows: 1fr 1fr 200px;

                        .primary-metric {
                            grid-area: 1 / 1 / 2 / 2;
                        }

                        .detail-table {
                            grid-area: 2 / 1 / 3 / 2;
                        }

                        .tide-chart {
                            min-height: 200px;
                            grid-area: 3 / 1 / 4 / 2;

                        }
                    }
                }
            }
        }
    }
}
</style>
function createWebSocket($config, callbacks) {
    let socket;

    function connect() {
        console.log("connecting to:" + $config.websocketUrl);
        socket = new WebSocket($config.websocketUrl);

        socket.onopen = () => callbacks.onOpen();
        socket.onclose = e => {
            console.log('WebSocket closed. Reconnecting in 1s...', e.reason);
            setTimeout(connect, 1000); // Reconnect after 1 second
        };
        socket.onmessage = e => callbacks.onMessage(e);
        socket.onerror = err => {
            console.error('WebSocket encountered an error:', err.message, 'Closing WebSocket');
            socket.close();
        };
    }

    connect(); // Initial connection
    return () => socket; // Return a function to get the current socket instance
}

export default createWebSocket;
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon, FontAwesomeLayers } from '@fortawesome/vue-fontawesome'
import { faSunrise, faSunset } from '@fortawesome/pro-solid-svg-icons'
import { faArrowsLeftRightToLine } from '@fortawesome/pro-regular-svg-icons';

library.add(faSunrise)
library.add(faSunset)
library.add(faArrowsLeftRightToLine)

const app = createApp(App);

app.component('font-awesome-icon', FontAwesomeIcon);
app.component('font-awesome-layers', FontAwesomeLayers);
app.use(router).mount('#app')
<template>
    <div class="block wind-block">
        <h3 class="block-header">Wind</h3>
        <div class="wind-block-features">
            <div class="primary-metric">
                <div class="val primary">
                    <div class="mono">{{ numeric(windSpeedLast?.State, 2, windSpeedLast?.AccuracyDecimals || 0) }}</div>
                    <div class="unit">mph</div>
                    <div class="mono">{{ windDirCardinalLast?.State }}</div>
                </div>
                <div class="wind__label">Current</div>
            </div>
            <div class="secondary-detail avg">
                <div class="val">
                    <div class="mono">{{ numeric(windSpeedAvgLast10Min?.State, 2, windSpeedAvgLast10Min?.AccuracyDecimals ||
                        0) }}</div>
                    <div class="unit">mph</div>
                    <div class="mono">{{ windDirCardinalAvgLast10Min?.State }}</div>
                </div>
                <div class="label">Avg 10m</div>
            </div>
            <div class="secondary-detail gust">
                <div class="val">
                    <div class="mono">{{ numeric(windSpeedHiLast10Min?.State, 2, windSpeedHiLast10Min?.AccuracyDecimals ||
                        0) }}</div>
                    <div class="unit">mph</div>
                    <div class="mono"> {{ windDirCardinalAtHiSpeedLast10Min?.State }}</div>
                </div>
                <div class="label">Gust 10m</div>
            </div>
            <div class="windrose-chart">
                <figure id="windrose-chart"></figure>
            </div>
            <div class="wind-chart">
                <figure id="wind-chart"></figure>
            </div>
        </div>
    </div>
</template>
<script>
import { numeric } from "../util";

export default {
    name: 'WindBlock',
    props: ['windSpeedLast', 'windDirCardinalLast', 'windSpeedAvgLast10Min', 'windDirCardinalAvgLast10Min', 'windSpeedHiLast10Min', 'windDirCardinalAtHiSpeedLast10Min'],
    async setup() {
        return {
            numeric,
        };
    },
}
</script>
<style lang="scss">
.weather {

    --wind-chart-min-height: 400px;
    --windrose-chart-height: 400px;
    --windrose-chart-width: 450px;

    // iphone
    @media only screen and (max-height: 850px) {
        --wind-chart-min-height: 200px;
        --windrose-chart-height: 200px;
    }


    &.weather--kioskmode {
        --wind-chart-min-height: 350px;
        --windrose-chart-height: 250px;
    }

    .blocks {
        .wind-block {
            .wind-block-features {
                display: grid;
                grid-template-columns: 250px 250px 400px 1fr;
                grid-template-rows: repeat(2, 1fr);
                grid-column-gap: 0px;
                grid-row-gap: 0px;

                .val {
                    width: 100%;

                    :nth-child(1) {
                        width: 4ch;
                        margin-right: 10px;
                        text-align: right;
                    }

                    :nth-child(3) {
                        text-align: left;
                        width: 3ch;
                        margin-left: 10px;
                    }
                }

                .secondary-detail {
                    .label {
                        text-align: center;
                    }
                }

                .secondary-detail .val {
                    width: 100%;
                    text-align: center;

                    :nth-child(1) {
                        margin-right: 10px;
                        text-align: right;
                    }

                    :nth-child(3) {
                        text-align: left;
                        margin-left: 10px;
                    }
                }

                .primary-metric {
                    grid-area: 1 / 1 / 2 / 3;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                }

                .secondary-detail.avg {
                    grid-area: 2 / 1 / 3 / 2;
                }

                .secondary-detail.gust {
                    grid-area: 2 / 2 / 3 / 3;
                }

                .windrose-chart {
                    grid-area: 1 / 3 / 3 / 4;
                    min-height: var(--windrose-chart-height);
                    height: var(--windrose-chart-height);
                }

                .wind-chart {
                    grid-area: 1 / 4 / 3 / 5;
                    min-height: var(--wind-chart-min-height);
                    height: var(--wind-chart-min-height);
                }

                @media only screen and (max-width: 1400px) {
                    grid-template-columns: repeat(3, 1fr);
                    grid-template-rows: repeat(2, 1fr) 400px;

                    .primary-metric {
                        grid-area: 1 / 1 / 2 / 3;
                    }

                    .secondary-detail.avg {
                        grid-area: 2 / 1 / 3 / 2;
                    }

                    .secondary-detail.gust {
                        grid-area: 2 / 2 / 3 / 3;
                    }

                    .windrose-chart {
                        grid-area: 1 / 3 / 3 / 4;
                    }

                    .wind-chart {
                        grid-area: 3 / 1 / 4 / 4;
                    }
                }

                @media only screen and (max-width: 600px) {
                    grid-template-columns: repeat(2, 1fr);
                    grid-template-rows: 130px 160px var(--windrose-chart-height) var(--wind-chart-min-height);

                    .primary-metric {
                        grid-area: 1 / 1 / 2 / 3;
                    }

                    .secondary-detail.avg {
                        grid-area: 2 / 1 / 3 / 2;
                    }

                    .secondary-detail.gust {
                        grid-area: 2 / 2 / 3 / 3;
                    }

                    .windrose-chart {
                        grid-area: 3 / 1 / 4 / 3;
                    }

                    .wind-chart {
                        grid-area: 4 / 1 / 5 / 3;
                    }
                }


            }
        }
    }

    &.weather--kioskmode {


        .blocks {
            .wind-block {
                .wind-block-features {
                    display: grid;
                    grid-template-columns: 1fr 1fr var(--windrose-chart-width);
                    grid-template-rows: 150px 100px 1fr;
                    grid-column-gap: 0px;
                    grid-row-gap: 0px;

                    .primary-metric {
                        grid-area: 1 / 1 / 2 / 3;
                    }

                    .secondary-detail.avg {
                        grid-area: 2 / 1 / 3 / 2;
                    }

                    .secondary-detail.gust {
                        grid-area: 2 / 2 / 3 / 3;
                    }

                    .windrose-chart {
                        grid-area: 1 / 3 / 3 / 4;
                    }

                    .wind-chart {
                        grid-area: 3 / 1 / 4 / 4;
                    }

                    @media only screen and (max-width: 1200px) {
                        grid-template-columns: repeat(2, 1fr);
                        grid-template-rows: repeat(2, 100px) 1fr;

                        .primary-metric {
                            grid-area: 1 / 1 / 2 / 3;
                        }

                        .secondary-detail .val {
                            font-size: 0.9rem;
                        }

                        .secondary-detail.avg {
                            grid-area: 2 / 1 / 3 / 2;
                        }

                        .secondary-detail.gust {
                            grid-area: 2 / 2 / 3 / 3;
                        }

                        .windrose-chart {
                            display: none;
                        }

                        .wind-chart {
                            min-height: 200px;
                            grid-area: 4 / 1 / 5 / 3;
                        }
                    }

                    // kindle fire kiosk mode
                    @media only screen and (max-height:1280px) {
                        grid-template-rows: 90px 65px 1fr;

                        .wind-chart {
                            min-height: 150px;
                            height: 150px;
                        }
                    }
                }
            }
        }
    }
}

// .wind-last__secondary {
//     margin-top: 1rem;
//     display: flex;
//     justify-content: space-between;
//     width: 100%;
// }
// .weather {
//     .blocks {
//         .wind-row {
//             .wind__detail {
//                 flex-direction: row;
//                 .wind__detail__col {
//                     display: flex;
//                     flex-direction: column;
//                     align-content: center;
//                 }
//             }
//             #windrose-chart {
//                 height: 100%;
//                 width: 400px;
//             }
//             #wind-chart {
//                 height: 100%;
//             }
//             .wind-chart {
//                 flex-grow: 1;
//             }
//         }
//         @media only screen and (max-width: 800px) {
//             .wind-row {
//                 flex-direction: column;
//                 .wind__detail {
//                     flex-direction: column !important;
//                     .wind__detail__col {
//                         width: 100%;
//                     }
//                     .windrose-chart,
//                     #windrose-chart {
//                         height: 100%;
//                         width: 100%;
//                     }
//                 }
//             }
//         }
//     }
//     &.weather--kioskmode {
//         .blocks {
//             .wind-row {
//                 flex-direction: column;
//                 .wind__detail {
//                     #wind-chart {
//                         height: 100%;
//                     }
//                     .wind-chart {
//                         flex-grow: 0;
//                     }
//                 }
//             }
//             @media only screen and (max-width: 800px) {
//                 .wind-row {
//                     .wind__detail {
//                         .val.primary,
//                         .wind__label {
//                             display: none;
//                         }
//                         .wind-chart {
//                             height: 150px;
//                         }
//                         .windrose-chart {
//                             display: none;
//                         }
//                         .secondary-detail .val {
//                             width: 100%;
//                             :nth-child(1),
//                             :nth-child(2) {
//                                 display: inline;
//                             }
//                             :nth-child(3) {
//                                 text-align: right;
//                                 width: auto;
//                             }
//                         }
//                     }
//                 }
//             }
//         }
//     }
// }
</style>
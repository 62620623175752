import Highcharts from "highcharts";
import Moment from "moment";
import { intervalAppropriateDate, numeric } from "../../util";

export default function () {

    const chart = Highcharts.chart('wind-chart', {
        chart: {
            type: 'column',
            scrollablePlotArea: {
                // minWidth: 600,
                scrollPositionX: 1
            },
            styledMode: true
        },
        credits: {
            enabled: false
        },
        title: {
            text: 'Wind Speed',
            align: 'left'
        },
        // subtitle: {
        //   text: 'Today',
        //   align: 'left'
        // },
        xAxis: {
            type: 'datetime',
            labels: {
                overflow: 'justify',
                formatter() {
                    return intervalAppropriateDate(this.value, this.axis.series[0].options.pointInterval);
                },
            },
            plotBands: [{
                color: '#ffdab9',
                from: Moment('Jan 7, 2022 8:00 AM'),
                to: Moment('Feb 4, 2022 5:00 AM'),
                label: {
                    text: 'Wind Gauge Offline',
                }
            }, {
                color: '#ffdab9',
                from: Moment('Feb 21, 2022 12:01 AM'),
                to: Moment('April 19, 2022 3:48 PM'),
                label: {
                    text: 'Wind Gauge Offline',
                }
            }],
        },
        yAxis: {
            title: {
                text: 'mph'
            },
            minorGridLineWidth: 0,
            gridLineWidth: 0,
            alternateGridColor: null,
        },
        tooltip: {
            formatter() {
                const ts = intervalAppropriateDate(this.x, this.series.options.pointInterval);
                return `${ts}<br>${this.series.name} <b>${this.y} mph</b>`;
            }
        },
        plotOptions: {
            spline: {
                // lineWidth: 4,
                states: {
                    hover: {
                        lineWidth: 5
                    }
                },
                marker: {
                    enabled: false
                },
            },
            column: {}
        },
        series: [ // wind chart
            {
                name: "Gust",
                data: [],
                type: 'column',
            }, {
                name: "Average",
                data: [],
                type: 'spline',
            }
        ],
        navigation: {
            menuItemStyle: {
                fontSize: '10px'
            }
        },
        annotations: [{
            draggable: '',
            labelOptions: {
                shape: 'connector',
                align: 'right',
                justify: false,
                crop: true,
                style: {
                    fontSize: '0.7rem',
                    textOutline: '1px white'
                }
            },
            labels: [],
        }],
    });

    return {
        chart,
        gust(m) {
            chart.series[0].setData(m.Values);
            chart.series[0].update({
                pointInterval: m.Interval * 1000,
                pointStart: Moment(m.StartDate).valueOf(),
                type: "spline",
            });
        },
        avg(m) {
            chart.series[1].setData(m.Values);
            chart.series[1].update({
                pointInterval: m.Interval * 1000,
                pointStart: Moment(m.StartDate).valueOf(),
                type: "spline",
            });
        },
        max_label: (m) => {
            if (!m) {
                return;
            }

            const ts = Moment(m.Timestamp);
            const toTrunc = ts.valueOf() % chart.series[0].userOptions.pointInterval;
            const startOf = Moment(ts.valueOf() - toTrunc);

            const x = startOf.valueOf();
            const y = numeric(m.Value, 3, 1);

            chart.update({
                annotations: [{
                    labels: [{
                        allowOverlap: true,
                        text: `High - ${m.Value} mph`,
                        point: {
                            x,
                            y,
                            xAxis: 0,
                            yAxis: 0
                        }
                    },],
                },
                {}
                ]
            })
        }
    };
}
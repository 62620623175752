import { ref } from 'vue';

export default function createTextWindowAggregation({ ID, Name, Values, LastUpdate, Unit, AccuracyDecimals, StartDate, Interval }) {
    const sensor = {
        ID: ref(ID),
        Name: ref(Name),
        Values: ref(Values),
        LastUpdate: ref(LastUpdate),
        Unit: ref(Unit),
        AccuracyDecimals: ref(AccuracyDecimals),
        StartDate: ref(StartDate),
        Interval: ref(Interval),
        valuesFromSource({ Name, Values, LastUpdate, Unit, AccuracyDecimals, StartDate, Interval }) {
            sensor.Name.value = Name;
            sensor.Values.value = Values;
            sensor.LastUpdate.value = LastUpdate;
            sensor.Unit.value = Unit;
            sensor.AccuracyDecimals.value = AccuracyDecimals;
            sensor.StartDate.value = StartDate;
            sensor.Interval.value = Interval;
        },
        updateState(ts, state) {
            sensor.Values.value = state;
            sensor.LastUpdate.value = ts;
        }
    };

    return sensor;
}
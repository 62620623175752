import CurrentConditions from './views/CurrentConditions.vue'
import { createRouter, createWebHistory } from 'vue-router'

const routes = [
    {
        path: '/',
        name: 'CurrentConditions',
        component: CurrentConditions
    }
]

const router = createRouter({ history: createWebHistory(), routes })
export default router
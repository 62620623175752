import { ref } from 'vue';
import HttpClient from "./lib/httpClient";
import createWebSocket from "./lib/websocket";
import $config from "./config";
import Moment from "moment";

export function useSyncManager(state) {
    const httpClient = new HttpClient($config.apiUrl);
    let websocket = null;
    const lastUpdate = ref(null);

    const fetchInitialConditions = async () => {
        try {
            const {
                Sensors = {},
                TextSensors = {},
                Forecasts = {},
                TextForecasts = {},
                WindowAggregations = {},
                TextWindowAggregations = {},
                PeriodAggregations = {},
            } = await httpClient.getCurrent();

            // sensors
            for (const [, sensor] of Object.entries(Sensors)) {
                if (!state.sensors[sensor.ID]) {
                    continue;
                }
                state.sensors[sensor.ID].valuesFromSource(sensor);
            }

            // text sensors
            for (const [, sensor] of Object.entries(TextSensors)) {
                if (!state.textSensors[sensor.ID]) {
                    continue;
                }
                state.textSensors[sensor.ID].valuesFromSource(sensor);
            }

            // forecasts
            for (const [, sensor] of Object.entries(Forecasts)) {
                if (!state.forecasts[sensor.ID]) {
                    continue;
                }
                console.log(sensor)
                state.forecasts[sensor.ID].valuesFromSource(sensor);
            }

            // text forecasts
            for (const [, sensor] of Object.entries(TextForecasts)) {
                if (!state.textForecasts[sensor.ID]) {
                    continue;
                }
                state.textForecasts[sensor.ID].valuesFromSource(sensor);
            }

            // window aggregations
            for (const [, sensor] of Object.entries(WindowAggregations)) {
                if (!state.windowAggregations[sensor.ID]) {
                    continue;
                }
                state.windowAggregations[sensor.ID].valuesFromSource(sensor);
            }

            // text window aggregations
            for (const [, sensor] of Object.entries(TextWindowAggregations)) {
                if (!state.textWindowAggregations[sensor.ID]) {
                    continue;
                }
                state.textWindowAggregations[sensor.ID].valuesFromSource(sensor);
            }

            // period aggregations
            for (const [, sensor] of Object.entries(PeriodAggregations)) {
                if (!state.periodAggregations[sensor.ID]) {
                    continue;
                }
                state.periodAggregations[sensor.ID].valuesFromSource(sensor);
            }
        } catch (error) {
            console.error('Error in HTTP fetch - fetchInitialConditions:', error);
            throw error;
        }
    };

    const initializeWebSocket = (entityUpdateCb) => {
        websocket = createWebSocket($config, {
            onOpen: () => console.log("WebSocket connection opened"),
            onClose: () => console.log("WebSocket connection closed"),
            onMessage: (e) => handleWebsocketMessage(e, entityUpdateCb),
            onError: err => {
                console.error('WebSocket encountered an error:', err.message);
                websocket.close();
            },
        });
    };


    const handleWebsocketMessage = (e, entityUpdateCb) => {
        try {
            const msg = JSON.parse(e.data);
            switch (msg.Method) {
                case "OnSensorState": {
                    let { SensorID, State, Timestamp } = msg.Data;
                    if (state.sensors[SensorID]) {
                        state.sensors[SensorID].updateState(Timestamp, State);
                        entityUpdateCb("sensor", SensorID);
                        lastUpdate.value = Moment();
                    }
                    break;
                }
                case "OnTextSensorState": {
                    let { TextSensorID, State, Timestamp } = msg.Data;
                    if (state.textSensors[TextSensorID]) {
                        state.textSensors[TextSensorID].updateState(Timestamp, State);
                        entityUpdateCb("textSensor", TextSensorID);
                        lastUpdate.value = Moment();
                    }
                    break;

                }
                case "OnForecastEventChange": {
                    let { ForecastID, HorizonActual, EffectiveEvent, NextEvents } = msg.Data;
                    if (state.forecasts[ForecastID]) {
                        state.forecasts[ForecastID].updateEvents({ HorizonActual, EffectiveEvent, NextEvents });
                        entityUpdateCb("forecast", ForecastID);
                        lastUpdate.value = Moment();
                    }
                    break;
                }
                case "OnTextForecastEventChange": {
                    let { TextForecastID, HorizonActual, EffectiveEvent, NextEvents } = msg.Data;
                    if (state.textForecasts[TextForecastID]) {
                        state.textForecasts[TextForecastID].updateEvents({ HorizonActual, EffectiveEvent, NextEvents });
                        entityUpdateCb("textForecast", TextForecastID);
                        lastUpdate.value = Moment();
                    }
                    break;
                }
                case "OnWindowAggregationValues": {
                    let { WindowAggregationID, Values, Timestamp } = msg.Data;
                    if (state.windowAggregations[WindowAggregationID]) {
                        state.windowAggregations[WindowAggregationID].updateState(Timestamp, Values);
                        entityUpdateCb("windowAggregation", WindowAggregationID);
                        lastUpdate.value = Moment();
                    }
                    break;
                }
                case "OnTextWindowAggregationValues": {
                    let { TextWindowAggregationID, Values, Timestamp } = msg.Data;
                    if (state.textWindowAggregations[TextWindowAggregationID]) {
                        state.textWindowAggregations[TextWindowAggregationID].updateState(Timestamp, Values);
                        entityUpdateCb("textWindowAggregation", TextWindowAggregationID);
                        lastUpdate.value = Moment();
                    }
                    break;
                }
                case "OnPeriodAggregationValue": {
                    let { PeriodAggregationID, Values, Timestamp } = msg.Data;
                    if (state.periodAggregations[PeriodAggregationID]) {
                        state.periodAggregations[PeriodAggregationID].updateState(Timestamp, Values);
                        entityUpdateCb("periodAggregation", PeriodAggregationID);
                        lastUpdate.value = Moment();
                    }
                    break;
                }
            }
        } catch (err) {
            console.error("failed to parse ws message: ", err, e);
        }
    };

    return { fetchInitialConditions, initializeWebSocket, lastUpdate };
}
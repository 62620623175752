import { reactive, computed, ref } from 'vue';
import createSensor from "./lib/sensor";
import createTextSensor from "./lib/textSensor";
import createForecast from "./lib/forecast";
import createTextForecast from "./lib/textForecast";
import createWindowAggregation from "./lib/windowAggregation";
import createTextWindowAggregation from "./lib/textWindowAggregation";
import createPeriodAggregation from "./lib/periodAggregation";
import $config from "./config.js";




const state = reactive({
    startDate: null,
    endDate: null,
    sensors: {},
    textSensors: {},
    forecasts: {},
    textForecasts: {},
    windowAggregations: {},
    textWindowAggregations: {},
    periodAggregations: {},
});

export const useStore = () => {

    const startDate = ref(null);
    const endDate = ref(null);
    const sensors = reactive(Object.fromEntries($config.watchEntities.sensors.map(id => [id, createSensor({ ID: id })])));
    const textSensors = reactive(Object.fromEntries($config.watchEntities.textSensors.map(id => [id, createTextSensor({ ID: id })])));
    const forecasts = reactive(Object.fromEntries($config.watchEntities.forecasts.map(id => [id, createForecast({ ID: id })])));
    const textForecasts = reactive(Object.fromEntries($config.watchEntities.textForecasts.map(id => [id, createTextForecast({ ID: id })])));
    const textWindowAggregations = reactive(Object.fromEntries($config.watchEntities.textWindowAggregations.map(id => [id, createTextWindowAggregation({ ID: id })])));
    const windowAggregations = reactive(Object.fromEntries($config.watchEntities.windowAggregations.map(id => [id, createWindowAggregation({ ID: id })])));
    const periodAggregations = reactive(Object.fromEntries($config.watchEntities.periodAggregations.map(id => [id, createPeriodAggregation({ ID: id })])));

    const viewWindowMode = computed(() => {
        const hours = (endDate.value - startDate.value) / 60 / 60;
        if (hours > 24 * 31) return "YEAR";
        if (hours > 24 * 7) return "MONTH";
        if (hours > 24) return "WEEK";
        return "DAY";
    });

    // build an index of metric aggregations for the current viewWindowMode, with key of aggregations
    // defined in config (metricAggrMap), and value of the most recent metric value from the main dataSource object
    // eg. {temp_high: 90}
    // where "temp_high" is a metricID, and 90 is the most recent value
    const aggrThisPeriod = computed(() => {
        const aggrs = {};

        for (const [metricId, chartModes] of Object.entries($config.metricAggrMap)) {
            const aggrMetric = chartModes?.[viewWindowMode.value];
            if (!aggrMetric) {
                console.warn(`aggregation "${metricId}" not configured for ${viewWindowMode.value} view`);
                continue;
            }
            aggrs[metricId] = state.periodAggregations[aggrMetric]?.Value;
        }
        return aggrs;
    });

    return {
        state: {
            startDate,
            endDate,
            sensors,
            textSensors,
            forecasts,
            textForecasts,
            windowAggregations,
            textWindowAggregations,
            periodAggregations,
        },
        viewWindowMode,
        aggrThisPeriod
    };
};

import Highcharts from "highcharts";
import Moment from "moment";
import { intervalAppropriateDate, numeric } from "../../util";

export default function (el, { rangeAppropriateDate }) {
    const chart = Highcharts.chart(el, {
        chart: {
            type: 'spline',
            styledMode: true
        },
        credits: {
            enabled: false
        },
        title: {
            text: '',
            align: 'left'
        },
        // subtitle: {
        //   text: 'Today',
        //   align: 'left'
        // },
        xAxis: {
            type: 'datetime',
            labels: {
                overflow: 'justify',
                formatter() {
                    return intervalAppropriateDate(this.value, this.axis.series[0].options.pointInterval);
                },
            },
            plotBands: [],

        },
        yAxis: [{
            title: {
                text: '°F'
            },
            minorGridLineWidth: 0,
            gridLineWidth: 0,
            alternateGridColor: null,
            plotLines: [{
                color: 'red',
                value: 32,
                width: 1,
                label: {
                    text: 'Freezing', // Content of the label. 
                    align: 'left', // Positioning of the label. 
                    // Default to center.x: +10 // Amount of pixels the label will be repositioned according to the alignment. 
                }
            }],
        }, {
            opposite: true,
            title: {
                text: '%'
            },
            minorGridLineWidth: 0,
            gridLineWidth: 0,
            alternateGridColor: null,
        }],

        plotOptions: {
            spline: {
                lineWidth: 4,
                states: {
                    hover: {
                        lineWidth: 5
                    }
                },
                marker: {
                    enabled: false
                },
                // pointInterval: 1000 & 6,
                // pointStart: startDate * 1000
            },
            arearange: {
                // pointInterval,
                // pointStart: startDate * 1000
            }
        },
        tooltip: {
            formatter() {
                const ts = intervalAppropriateDate(this.x, this.series.options.pointInterval);
                return `${ts}<br>${this.series.name} <b>${numeric(this.y, 3, 1)}${this.series.name === "Humidity" ? '%' : ' °F'}</b>`;
            }
        },
        series: [{
            name: "Temp",
            data: [],
            yAxis: 0,
        },
        {
            name: "Feels Like",
            data: [],
            yAxis: 0,
        },
        {
            name: "Humidity",
            visible: false,
            data: [],
            yAxis: 1,
        }
        ],
        annotations: [{
            animation: {
                defer: 0
            },
            draggable: '',
            x: 0,
            y: 32,
            labelOptions: {
                className: "highcharts-label--temp-high",
                align: 'right',
                justify: false,
                crop: false,
                style: {
                    fontSize: '0.7rem',
                    textOutline: '1px white',
                },
                x: 0,
                y: 32,
            },
            labels: [{}],
        },
        {
            animation: {
                defer: 0
            },
            draggable: 'xy',
            labelOptions: {
                className: "highcharts-label--temp-low",
                align: 'right',
                justify: false,
                crop: false,
                style: {
                    fontSize: '0.7rem',
                    textOutline: '1px white',
                }
            },
            labels: [{}],
        }
        ]
    });

    var minTemp = [];
    var maxTemp = [];

    function mergeMinMaxTemp() {
        if (maxTemp.length > 0 && minTemp.length > 0) {
            let data = [];
            const pointCount = maxTemp.length > minTemp.length ? maxTemp.length : minTemp.length;
            for (var i = 0; i < pointCount; i++) {
                data.push([minTemp[i], maxTemp[i]]);
            }
            chart.series[0].setData(data);
        }
    }

    var minHum = [];
    var maxHum = [];

    function mergeMinMaxHum() {
        if (maxHum.length > 0 && minHum.length > 0) {
            let data = [];
            const pointCount = maxHum.length > minHum.length ? maxHum.length : minHum.length;
            for (var i = 0; i < pointCount; i++) {
                data.push([minHum[i], maxHum[i]]);
            }
            chart.series[2].setData(data);
        }
    }

    return {
        chart,
        avg_temp(m) {
            chart.series[0].setData(m.Values);
            chart.series[0].update({
                pointInterval: m.Interval * 1000,
                pointStart: Moment(m.StartDate).valueOf(),
                type: "spline",
            });
        },
        avg_heatindex(m) {
            chart.series[1].setData(m.Values);
            chart.series[1].update({
                pointInterval: m.Interval * 1000,
                pointStart: Moment(m.StartDate).valueOf(),
            });
        },
        avg_hum(m) {
            chart.series[2].setData(m.Values);
            chart.series[2].update({
                pointInterval: m.Interval * 1000,
                pointStart: Moment(m.StartDate).valueOf(),
                type: "spline",
            });
        },
        min_temp(m) {
            chart.series[0].update({
                pointInterval: m.Interval * 1000,
                pointStart: Moment(m.StartDate).valueOf(),
                type: "arearange",
            });
            minTemp = m.Values;
            mergeMinMaxTemp();
        },
        max_temp(m) {
            chart.series[0].update({
                pointInterval: m.Interval * 1000,
                pointStart: Moment(m.StartDate).valueOf(),
                type: "arearange",
            });
            maxTemp = m.Values;
            mergeMinMaxTemp();
        },
        min_hum(m) {
            chart.series[2].update({
                pointInterval: m.Interval * 1000,
                pointStart: Moment(m.StartDate).valueOf(),
                type: "arearange",
            });
            minHum = m.Values;
            mergeMinMaxHum();
        },
        max_hum(m) {
            chart.series[2].update({
                pointInterval: m.Interval * 1000,
                pointStart: Moment(m.StartDate).valueOf(),
                type: "arearange",
            });
            maxHum = m.Values;
            mergeMinMaxHum();
        },
        max_label_temp: (m) => {
            if (!m) {
                return;
            }

            const ts = Moment(m.Timestamp);
            const toTrunc = ts.valueOf() % chart.series[0].userOptions.pointInterval;
            const startOf = Moment(ts.valueOf() - toTrunc);

            const x = startOf.valueOf();
            const y = numeric(m.Value, 3, m.AccuracyDecimals);

            chart.update({
                annotations: [{
                    labels: [{
                        allowOverlap: true,
                        useHTML: true,
                        text: `H: ${m.Value} °F<br>@ ${rangeAppropriateDate(m.Timestamp)}`,
                        point: {
                            x,
                            y,
                            xAxis: 0,
                            yAxis: 0
                        },
                        x: -30,
                        y: 32,
                    },],
                },
                {}
                ]
            })
        },
        min_label_temp: (m) => {
            if (!m) {
                return;
            }

            const ts = Moment(m.Timestamp);
            const toTrunc = ts.valueOf() % chart.series[0].userOptions.pointInterval;
            const startOf = Moment(ts.valueOf() - toTrunc);

            const x = startOf.valueOf();
            const y = numeric(m.Value, 3, m.AccuracyDecimals);

            chart.update({
                annotations: [
                    {}, {
                        labels: [{
                            allowOverlap: true,
                            useHTML: true,
                            text: `L: ${m.Value} °F<br>@ ${rangeAppropriateDate(m.Timestamp)}`,
                            point: {
                                x,
                                y,
                                xAxis: 0,
                                yAxis: 0
                            },
                            x: 0,
                            y: -16,
                        },],
                    },
                ]
            })
        },
    }
}
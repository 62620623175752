import Moment from "moment";

export function degreesToDirection(deg) {
    if (deg === undefined) {
        return "---";
    }

    if (deg >= 348.75 || deg < 11.25) {
        return "N"
    } else if (deg >= 11.25 && deg < 33.75) {
        return "NNE"
    } else if (deg >= 33.75 && deg < 56.25) {
        return "NE"
    } else if (deg >= 56.25 && deg < 78.75) {
        return "ENE"
    } else if (deg >= 78.75 && deg < 101.25) {
        return "E"
    } else if (deg >= 101.25 && deg < 123.75) {
        return "ESE"
    } else if (deg >= 123.75 && deg < 146.25) {
        return "SE"
    } else if (deg >= 146.25 && deg < 168.75) {
        return "SSE"
    } else if (deg >= 168.75 && deg < 191.25) {
        return "S"
    } else if (deg >= 191.25 && deg < 213.75) {
        return "SSW"
    } else if (deg >= 213.75 && deg < 236.25) {
        return "SW";
    } else if (deg >= 236.25 && deg < 258.75) {
        return "WSW";
    } else if (deg >= 258.75 && deg < 281.25) {
        return "W";
    } else if (deg >= 281.25 && deg < 303.75) {
        return "WNW"
    } else if (deg >= 303.75 && deg < 326.25) {
        return "NW"
    } else {
        return "NNW";
    }
}

export function rangeAppropriateDate(d, { start, end } = {}) {
    start = Moment(start);
    end = Moment(end);

    const hours = (end.valueOf() - start.valueOf()) / 1000 / 60 / 60;

    let format;
    if (hours < 24) {
        format = "h:mm A"
    } else if (hours < 24 * 7) {
        format = "dd h:mm A"
    } else {
        format = "M/DD h:mm A"
    }

    return Moment(d).format(format)
}

export function intervalAppropriateDate(d, interval){
    let format;
    if (interval <= 60 * 1000) {
        format = "h:mm A"
    } else if (interval <=  60 * 1000 * 5) {
        format = "dd h:mm A"
    } else {
        format = "M/DD h:mm A"
    }

    return Moment(d).format(format)
}

export function numeric(val, decimalsLeft, decimalsRight) {
    if (val === undefined) {
        return "-".repeat(decimalsLeft) + "." + "-".repeat(decimalsRight);
    }
    return parseFloat(val).toFixed(decimalsRight);
}
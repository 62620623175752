import { ref } from 'vue';

export default function createPeriodAggregation({ ID, Name, Value, Timestamp, LastUpdate, Unit, AccuracyDecimals }) {
    const sensor = {
        ID: ref(ID),
        Name: ref(Name),
        Value: ref(Value),
        LastUpdate: ref(LastUpdate),
        Timestamp: ref(Timestamp),
        Unit: ref(Unit),
        AccuracyDecimals: ref(AccuracyDecimals),
        valuesFromSource({ Name, Value, Timestamp, LastUpdate, Unit, AccuracyDecimals }) {
            sensor.Name.value = Name;
            sensor.Value.value = Value;
            sensor.Timestamp.value = Timestamp;
            sensor.LastUpdate.value = LastUpdate;
            sensor.Unit.value = Unit;
            sensor.AccuracyDecimals.value = AccuracyDecimals;
        },
        updateState(ts, state) {
            sensor.Value.value = state;
            sensor.LastUpdate.value = ts;
        }
    };

    return sensor;
}
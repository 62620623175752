<template>
    <div class="block temp-block">
        <h3 class="block-header">Temp / Humidity</h3>
        <div class="temp-block-features">
            <div class="primary-metric">
                <div class="val primary"><span class="mono">{{ numeric(temp?.State, 3, temp?.AccuracyDecimals || 0)
                }}</span> <span class="unit">°F</span></div>
                <div class="temp__feels-like">Feels like <span class="mono">{{ numeric(heatIndex?.State, 3,
                    heatIndex?.AccuracyDecimals || 0) }}</span> °F
                </div>
            </div>
            <div class="temp__detail__col detail-table">
                <div class="hum detail-table__row">
                    <div class="humidity__label">Humidity</div>
                    <div class="humidity__val mono">{{ numeric(hum?.State, 3, hum?.AccuracyDecimals || 0) }}%</div>
                </div>
                <div class="wind-chill detail-table__row">
                    <div class="heat-index__label">Wind Chill</div>
                    <div class="heat-index__val val mono">{{ numeric(windChill?.State, 3, windChill?.AccuracyDecimals || 0)
                    }} °F</div>
                </div>
                <div class="dew-point detail-table__row">
                    <div class="dew-point__label">Dew Point</div>
                    <div class="dew-point__val val mono">{{ numeric(dewPoint?.State, 3, dewPoint?.AccuracyDecimals || 0) }}
                        °F</div>
                </div>
                <div class="wet-bulb detail-table__row">
                    <div class="wet-bulb__label">Wet Bulb</div>
                    <div class="wet-bulb__val val mono">{{ numeric(wetBulb?.State, 3, wetBulb?.AccuracyDecimals || 0) }} °F
                    </div>
                </div>
            </div>
            <div class="temp-chart">
                <figure id="temp-chart"></figure>
            </div>
        </div>
    </div>
</template>
<script>
import { numeric } from "../util";

export default {
    name: 'TempBlock',
    props: ['temp', 'heatIndex', 'tempHigh', 'tempLow', 'hum', 'windChill', 'dewPoint', 'wetBulb'],
    async setup() {
        return {
            numeric,
        };
    },
}
</script>
<style lang="scss">
.weather {
    .blocks {
        .temp-block {
            .temp-block-features {
                display: grid;
                grid-template-columns: 400px 1fr;
                grid-template-rows: repeat(2, 1fr);
                grid-column-gap: 0px;
                grid-row-gap: 0px;

                .primary-metric {
                    grid-area: 1 / 1 / 2 / 2;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                }

                .detail-table {
                    grid-area: 2 / 1 / 3 / 2;
                    width: calc(100% - 1rem);
                    padding: 0 0.5rem;

                    .detail-table__row {
                        display: flex;
                        width: 100%;
                        justify-content: space-between;
                    }

                    .detail-table__row .val {
                        text-align: right;
                    }
                }

                .temp-chart {
                    min-height: 400px;
                    max-height: 400px;
                    grid-area: 1 / 2 / 3 / 3;

                    .highcharts-label--temp-high {
                        color: white;

                        .highcharts-label-box {
                            fill: red;
                        }
                    }

                    .highcharts-label--temp-low {
                        color: white;

                        .highcharts-label-box {
                            fill: blue;
                        }
                    }
                }

                @media only screen and (max-width: 1400px) {
                    grid-template-columns: repeat(2, 1fr);
                    grid-template-rows: repeat(2, 1fr);

                    .primary-metric {
                        grid-area: 1 / 1 / 2 / 2;
                    }

                    .detail-table {
                        grid-area: 1 / 2 / 2 / 3;
                    }

                    .temp-chart {
                        grid-area: 2 / 1 / 3 / 3;
                    }
                }

                @media only screen and (max-width: 600px) {
                    grid-template-columns: 1fr;
                    grid-template-rows: 175px 130px 1fr;

                    .primary-metric {
                        grid-area: 1 / 1 / 2 / 2;
                    }

                    .detail-table {
                        grid-area: 2 / 1 / 3 / 2;
                    }

                    .temp-chart {
                        grid-area: 3 / 1 / 4 / 2;
                    }
                }

                // iphone
                @media only screen and (max-height: 850px) {

                    // shorten the chart height so it doesn't take up so much of the screen
                    .temp-chart {
                        min-height: 200px;
                        max-height: 200px;
                    }
                }
            }
        }
    }

    &.weather--kioskmode {
        .blocks {
            .temp-block {
                .temp-block-features {
                    grid-template-columns: repeat(2, 1fr);
                    grid-template-rows: 130px 1fr;

                    .primary-metric {
                        grid-area: 1 / 1 / 2 / 2;

                        .temp__highlow__row {
                            margin: 0.5rem 0;
                        }
                    }

                    .detail-table {
                        grid-area: 1 / 2 / 2 / 3;
                    }

                    .temp-chart {
                        min-height: 400px;
                        max-height: 400px;
                        grid-area: 2 / 1 / 3 / 3;
                    }

                    @media only screen and (max-width: 1200px) {
                        grid-template-columns: 1fr;
                        grid-template-rows: 165px 110px 1fr;

                        .primary-metric {
                            grid-area: 1 / 1 / 2 / 2;
                        }

                        .detail-table {
                            grid-area: 2 / 1 / 3 / 2;
                        }

                        .temp-chart {
                            min-height: 200px;
                            max-height: 200px;
                            grid-area: 3 / 1 / 4 / 2;
                        }
                    }

                    // kindle fire kiosk mode
                    @media only screen and (max-height: 1280px) {
                        grid-template-rows: 100px 30px 1fr;

                        .dew-point,
                        .wet-bulb,
                        .wind-chill {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}
</style>